import MaxWidthContainer, { MaxWidthContainerProps } from "./MaxWidthContainer";

const Wrapper: React.FC<MaxWidthContainerProps> = (props) => (
  <MaxWidthContainer centered {...props} />
);

const Body: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <div className="py-4 px-2 px-sm-4">{children}</div>
);

const Page = { Wrapper, Body };

export default Page;
