import { Result } from "antd";

const ApplicationReceived: React.FC = () => {
  return (
    <div className="mx-auto">
      <Result
        status="success"
        title="The form has been submitted."
        subTitle="Thank you for your interest towards our mentorship programme. A confirmation email for your application has been sent to your registered mailbox. We will be in contact shortly for the next steps."
      />
    </div>
  );
};

export default ApplicationReceived;
