/* eslint-disable jsx-a11y/anchor-has-content */
import _ from "lodash";
import { useRef } from "react";

const useInvisibleLinks = <T extends string | number>(
  urls?: Record<T, string> | (string | undefined)[]
) => {
  const linkRefs = useRef<Partial<Record<T, HTMLAnchorElement | null>>>({});
  const invisibleLinks = (
    <div className="d-none">
      {(Object.entries(urls ?? {}) as [T, string][]).map(([id, url]) =>
        url ? (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
            ref={(el) => (linkRefs.current[id] = el)}
          />
        ) : null
      )}
    </div>
  );
  const openLink = (id?: T | null) => () => (!_.isNil(id) ? linkRefs.current[id]?.click() : null);
  return { invisibleLinks, openLink };
};

export default useInvisibleLinks;
