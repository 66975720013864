import { Result } from "antd";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

export interface Error404Props {
  mode: "admin" | "apply";
  buttonText?: string;
}

const Error404: React.FC<Error404Props> = ({ mode, buttonText }) => (
  <Result
    status="404"
    title="404 Not Found"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      buttonText ? (
        <Link to={`/${mode}`}>
          <Button primary>{buttonText}</Button>
        </Link>
      ) : null
    }
  />
);

export default Error404;
