import _ from "lodash";
import { FieldErrors } from "react-hook-form";
import { Message } from "semantic-ui-react";
import { FormState } from "../../../components/aahk/appFormBody/util";

export interface ErrorMessageListProps {
  errors?: FieldErrors<FormState>;
  hidden?: boolean;
}

type WithMessage = { message: string };

function findMessages(errors: WithMessage | WithMessage[]): string[] {
  if ("message" in errors) {
    return [errors.message as string];
  }
  return _.flatten(Object.values(errors).map((error) => findMessages(error)));
}

const ErrorMessageList: React.FC<ErrorMessageListProps> = ({ errors, hidden }) => {
  if (!errors || hidden) {
    return null;
  }

  const messages = findMessages(errors as WithMessage | WithMessage[]);

  if (messages.length === 0) {
    return null;
  }

  return (
    <Message error header="Some of the information you provided is invalid:" list={messages} />
  );
};

export default ErrorMessageList;
