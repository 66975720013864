import { Checkbox as AntdCheckbox, Space } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import JsxParser from "react-jsx-parser";
import "./Checkbox.css";
import Text from "./Text";
import {
  Options,
  compareOptions,
  defaultLabelRender,
  getLabels,
  standardiseOptions,
} from "../util";
import React from "react";

export interface CheckboxProps extends Omit<CheckboxGroupProps, "onChange"> {
  value?: string[];
  options: Options;
  customOption?: boolean;
  editing?: boolean;
  vertical?: boolean;
  sort?: boolean;
  hidden?: boolean;
  error?: boolean;
  onChange?: (value: CheckboxValueType[], event?: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  labelRender?: (labels: (string | undefined)[] | undefined) => JSX.Element;
}

const findValueInOptions = (options: { label: string; value: string }[]) => (value: string) => {
  return options.find((option) => option.value === value);
};

const Checkbox = React.forwardRef<HTMLDivElement, CheckboxProps>((props, ref) => {
  let {
    value,
    options: rawOptions = [],
    customOption: allowCustomOption = false,
    editing = true,
    vertical = true,
    sort = false,
    hidden = false,
    error = false,
    onChange: inputOnChange,
    onBlur,
    onFocus,
    className,
    labelRender,
    ...checkboxProps
  } = props;

  if (hidden) {
    return null;
  }

  const options = standardiseOptions(rawOptions);
  if (sort) {
    options.sort(compareOptions);
  }

  const valueIsAnOption = findValueInOptions(options);
  const values = value ?? [];

  if (!editing) {
    const labels = getLabels(options, values, allowCustomOption);
    const render = labelRender ?? defaultLabelRender;
    return render(labels);
  }

  let checkboxes = options.map(({ label, value }) => (
    <AntdCheckbox key={value} value={value}>
      <JsxParser jsx={label} className="d-inline" />
    </AntdCheckbox>
  ));

  if (allowCustomOption) {
    const customOption = values.find((value) => !valueIsAnOption(value)) ?? "";
    checkboxes.push(
      <AntdCheckbox key="__custom__" value={customOption}>
        <Text
          value={customOption}
          onChange={(value, event) =>
            inputOnChange?.([...values.filter(valueIsAnOption), value], event)
          }
          {...{ onBlur, onFocus, error }}
        />
      </AntdCheckbox>
    );
  }

  if (error) {
    className += " error";
  }

  let onChange;
  if (inputOnChange) {
    onChange = (value: CheckboxValueType[]) => inputOnChange?.(value ?? []);
  }

  return (
    <AntdCheckbox.Group {...checkboxProps} {...{ ref, value: values, onChange, className }}>
      <Space direction={vertical ? "vertical" : "horizontal"}>{checkboxes}</Space>
    </AntdCheckbox.Group>
  );
});

export default Checkbox;
