import _ from "lodash";
import QuestionConfig from "../../../types/formBodyConfig/QuestionConfig";

export type FormState = {
  [Key in keyof QuestionConfig]: QuestionConfig[Key] & { value?: unknown; hidden: boolean };
};

export function createFormState(
  formConfig?: QuestionConfig,
  formValues?: Record<string, unknown>
): FormState {
  if (!formConfig) {
    return {};
  }
  return Object.entries(formConfig).reduce((state, [key, fieldConfig]) => {
    const fieldKey = key;
    return {
      ...state,
      [fieldKey]: {
        ...fieldConfig,
        value: _.cloneDeep(formValues?.[fieldKey]),
        hidden: false,
      },
    };
  }, {}) as FormState;
}
