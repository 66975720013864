import Checkbox, { CheckboxProps } from "./components/Checkbox";
import DatePicker from "./components/DatePicker";
import Radio, { RadioProps } from "./components/Radio";
import Selection, { SelectionProps } from "./components/Selection";
import Text, { TextProps } from "./components/Text";
import Textarea, { TextareaProps } from "./components/Textarea";
import Upload, { UploadProps } from "./components/Upload";
import validate from "./validate";

export { Checkbox, DatePicker, Radio, Selection, Text, Textarea, Upload };

export const ValidatedText = validate<HTMLInputElement, TextProps>(Text);
export const ValidatedRadio = validate<HTMLDivElement, RadioProps>(Radio);
export const ValidatedCheckbox = validate<HTMLDivElement, CheckboxProps>(Checkbox);
export const ValidatedSelection = validate<HTMLElement, SelectionProps>(Selection);
export const ValidatedTextarea = validate<HTMLTextAreaElement, TextareaProps>(Textarea);
export const ValidatedUpload = validate<HTMLInputElement, UploadProps>(Upload);
