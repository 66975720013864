import _ from "lodash";
import { useForm } from "react-hook-form";

const useDraft = <FormValues extends {}>(values?: FormValues) => {
  const formMethods = useForm<FormValues>({ values, mode: "all" });
  const formState = formMethods.getValues();
  const draft = _.isEmpty(formState) ? undefined : formState;
  return { formMethods, draft };
};

export default useDraft;
