import _ from "lodash";
import { Table } from "semantic-ui-react";
import AppFormBody, { useFormState } from "../../../components/aahk/AppFormBody";
import { DEFAULT_DATE_FORMATS } from "../../../components/inputs/components/DatePicker";
import { FormBodyConfig } from "../../../types/FormBodyConfig";
import { FormConfig, InputtedFormConfig } from "./types";

export const compile = ({
  id,
  cycle,
  deadline,
  timestamp,
  ...inputtedFormConfig
}: InputtedFormConfig): FormConfig => {

  // eslint-disable-next-line no-eval
  const parsedConfig = _.mapValues(inputtedFormConfig, (value) => eval(`(${value})`));
  const {
    serializedQuestionConfigGenerator: questionConfigGenerator,
    serializedCallbackGenerator: callbackGenerator,
    courseList,
    uniList,
    examList,
    schoolList,
    ...config
  } = parsedConfig;

  return {
    ...config,
    id,
    cycle: cycle.year(),
    deadline: deadline.toISOString(),
    timestamp,
    questionConfigGenerator,
    callbackGenerator,
    context: {
      courseList,
      uniList,
      examList,
      schoolList,
    },
  };
};

export const generate = (config: FormConfig): FormBodyConfig => ({
  questionConfig: config.questionConfigGenerator(config.context),
  sectionConfig: config.sectionConfig,
  callbacks: config.callbackGenerator(config.context),
});

interface AppFormPreviewerProps {
  values?: InputtedFormConfig;
  editing: boolean;
}

const AppFormPreviewer: React.FC<AppFormPreviewerProps> = ({ values: config }) => {
  const formBodyConfig = config !== undefined ? generate(compile(config)) : undefined;
  const formMethods = useFormState(formBodyConfig, {});

  if (formBodyConfig === undefined || _.isEmpty(formMethods.getValues())) {
    return null;
  }

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Cycle</Table.HeaderCell>
            <Table.HeaderCell width={4}>Deadline</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{config?.cycle.year()}</Table.Cell>
            <Table.Cell>{config?.deadline.format(DEFAULT_DATE_FORMATS.date)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <AppFormBody
        {...{
          formMethods,
          sectionConfig: formBodyConfig.sectionConfig,
          callbacks: formBodyConfig.callbacks,
        }}
      />
    </>
  );
};

export default AppFormPreviewer;
