import { Route, Routes } from "react-router-dom";
import seo from "../../util/seo";
import Error404 from "./../errors/Error404";
import Application from "./Application";
import AppForm from "./AppForm";
import Applications from "./Applications";
import NavigationBar from "./NavigationBar";
import "./index.css";
import withAuth from "./withAuth";
import ErrorBoundary from "../../components/wrappers/ErrorBoundary";
import Page from "../../components/layout/Page";

export const ROUTES = [
  { path: "applications?", element: <Applications /> },
  { path: "application/:id", element: <Application /> },
  { path: "application/:id/edit", element: <AppForm /> },
  { path: "*", element: <Error404 mode="apply" buttonText="Back to Applications" /> },
  // { path: "aptitude-tests", Component: null },
  // { path: "interviews", Component: null },
];

const Layout: React.FC = () => {
  return (
    <Page.Wrapper>
      <NavigationBar />
      <Page.Body>
        <ErrorBoundary>
          <Routes>
            {ROUTES.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Routes>
        </ErrorBoundary>
      </Page.Body>
    </Page.Wrapper>
  );
};

const AuthenticatedLayout = withAuth(Layout);

const Apply = () => {
  seo({
    title: "Mentorship Application",
    metaDescription: "Access Abroad Hong Kong - Mentorship Application",
  });

  return <AuthenticatedLayout />;
};

export default Apply;
