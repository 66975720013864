import { Radio as AntdRadio, RadioChangeEvent, Space } from "antd";
import { RadioGroupProps as AntdRadioGroupProps } from "antd/es/radio";
import React from "react";
import { Options, compareOptions, getLabel, standardiseOptions } from "../util";
import "./Radio.css";
import Text from "./Text";

export interface RadioProps extends Omit<AntdRadioGroupProps, "onChange"> {
  value?: string;
  options: Options;
  customOption?: boolean;
  editing?: boolean;
  vertical?: boolean;
  sort?: boolean;
  hidden?: boolean;
  error?: boolean;
  onChange?: (value: string, event: React.ChangeEvent<HTMLDivElement> | RadioChangeEvent) => void;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
}

const Radio = React.forwardRef<HTMLDivElement, RadioProps>((props, ref) => {
  let {
    value,
    options: rawOptions = [],
    customOption: allowCustomOption = false,
    editing = true,
    vertical = true,
    sort = false,
    hidden = false,
    error = false,
    onChange: inputOnChange,
    onBlur,
    onFocus,
    className,
    ...radioProps
  } = props;

  if (hidden) {
    return null;
  }

  const options = standardiseOptions(rawOptions);
  if (sort) {
    options.sort(compareOptions);
  }

  if (!editing) {
    return <div>{getLabel(options, value, allowCustomOption)}</div>;
  }

  let radios = options.map((option) => {
    const { label, value } = option;
    return (
      <AntdRadio key={value} value={value}>
        {label}
      </AntdRadio>
    );
  });

  if (allowCustomOption) {
    const customOption = !value || options.find((option) => option.value === value) ? "" : value;
    radios.push(
      <AntdRadio key="__custom__" value={customOption}>
        <Text value={customOption} onChange={inputOnChange} {...{ onBlur, onFocus, error }} />
      </AntdRadio>
    );
  }

  if (error) {
    className += " error";
  }

  let onChange;
  if (inputOnChange) {
    onChange = (event: RadioChangeEvent) => inputOnChange?.(event.target.value, event);
  }

  return (
    <AntdRadio.Group {...{ ref, radioProps, value, onChange, className }}>
      <Space direction={vertical ? "vertical" : "horizontal"}>{radios}</Space>
    </AntdRadio.Group>
  );
});

export default Radio;
