import prettierPluginBabel from "prettier/plugins/babel";
import prettierPluginEstree from "prettier/plugins/estree";
import prettier from "prettier/standalone";
import * as R from "ramda";
import useSWR from "swr";
import { RequestError } from "../../../../types/Error";
import { fetcher } from "../../../../util/request";
import { Stringify } from "../types";
import { ConfigVariables } from "../types";

const prettify = (str: string) =>
  prettier.format(str, {
    parser: "babel",
    plugins: [prettierPluginBabel, prettierPluginEstree],
  });

const prettifyConfigValue = async <SerializedConfig,>(
  value: SerializedConfig[keyof SerializedConfig]
) => {
  let str: string;
  if (typeof value === "string" && value.includes("function")) {
    str = await prettify(value);
  } else {
    if (typeof value === "string") {
      str = await prettify(`const __DUMMY__ = "${value}";`);
      str = str.slice(0, str.lastIndexOf(";") - 1).replace('const __DUMMY__ = "', "");
    } else {
      str = await prettify(`const __DUMMY__  = ${JSON.stringify(value)};`);
      str = str.slice(0, str.lastIndexOf(";")).replace("const __DUMMY__ = ", "");
    }
  }
  return str;
};

const prettifyConfig =
  <ConfigKeys extends string>(configVariables: ConfigVariables<ConfigKeys>) =>
  async <SerializedConfig extends Record<ConfigKeys, any>>(
    config: SerializedConfig
  ): Promise<Stringify<SerializedConfig>> => {
    const prettifiedValues = await Promise.all(
      R.keys(configVariables).map((key) =>
        configVariables[key].type !== "metadata"
          ? prettifyConfigValue(config?.[key])
          : config?.[key]
      )
    );
    let stringifiedConfig = R.fromPairs(R.zip(R.keys(configVariables), prettifiedValues));
    return stringifiedConfig as Stringify<SerializedConfig>;
  };

const useConfig = <
  ConfigKeys extends string,
  SerializedConfig extends Record<ConfigKeys, any>,
  InputtedConfig extends Record<ConfigKeys, any>,
>(
  id: string | undefined,
  options: {
    endpoint: string;
    configVariables: ConfigVariables<ConfigKeys>;
    prepareConfigForInput: (config: Stringify<SerializedConfig>) => InputtedConfig;
  }
) => {
  let swrTransform = R.compose(
    (data: Promise<Stringify<SerializedConfig>>) => data.then(options.prepareConfigForInput),
    (data: Promise<SerializedConfig>) => data.then(prettifyConfig(options.configVariables)),
    fetcher
  );

  const {
    data: config,
    error,
    isLoading,
    ...swr
  } = useSWR<InputtedConfig, RequestError>(
    id !== undefined ? `/admin/${options.endpoint}/${id}` : null,
    swrTransform
  );

  return { config, error, isLoading, ...swr };
};

export default useConfig;
