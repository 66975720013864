import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { FormProvider, UseFormReturn, useFormContext, useWatch } from "react-hook-form";
import { usePrevious, useSet } from "react-use";
import { toRoman } from "roman-numerals";
import { CallbackEntry } from "../../types/formBodyConfig/CallbackConfig";
import { BasicConfigs, FieldType, TableConfigs } from "../../types/formBodyConfig/QuestionConfig";
import SectionConfig from "../../types/formBodyConfig/SectionConfig";
import Validator from "../../util/validator";
import "./AppFormBody.css";
import CallbacksInvoker from "./appFormBody/CallbacksInvoker";
import FieldElement from "./appFormBody/components/FieldElement";
import FieldLayout from "./appFormBody/components/FieldLayout";
import Table from "./appFormBody/components/Table";
import {
  ValidationProvider,
  useValidationContext,
} from "./appFormBody/components/ValidationContext";
import useFormState from "./appFormBody/hooks/useFormState";
import { FormState } from "./appFormBody/util";

// TODO Add semantic UI tooltip
// TODO Add upload size limit
// TODO Test if Table can use Upload as well
// TODO Test if all inputs can become non-editable

const useCallbackDependencies = (callbacks: CallbackEntry[]): FormState => {
  const { getValues, reset } = useFormContext<FormState>();
  useWatch({ name: _.flatten(callbacks.map(({ inputs }) => inputs)) });

  const currentState = getValues();
  const invoker = new CallbacksInvoker(currentState, callbacks);
  const nextState = invoker.invoke();
  if (nextState !== currentState) {
    reset(nextState, {
      keepDefaultValues: true,
      keepDirty: true,
      keepErrors: true,
      keepDirtyValues: true,
      keepIsSubmitted: true,
      keepIsValid: true,
      keepSubmitCount: true,
      keepTouched: true,
    });
  }
  return currentState;
};

interface ExpandAccordionsProps {
  toggle: (key: string) => void;
}

// TODO This hook is not working as desired
const useExpandAccordions = (sectionConfig: SectionConfig): ExpandAccordionsProps => {
  const { allErrorsVisible } = useValidationContext();
  const prevAllErrorsVisible = usePrevious(allErrorsVisible);
  const [_set, { add, toggle }] = useSet<string>(new Set());
  useEffect(() => {
    if (allErrorsVisible && !prevAllErrorsVisible) {
      sectionConfig.forEach(({ title }) => add(title));
    }
  }, [prevAllErrorsVisible, allErrorsVisible]);
  return { toggle };
};

interface FormBodyProps {
  sectionConfig: SectionConfig;
  callbacks: Array<CallbackEntry>;
  fileUploadLink?: string;
  fileDeleteLink?: string;
  editing?: boolean;
}

const FormBody: React.FC<FormBodyProps> = ({
  sectionConfig,
  callbacks,
  fileUploadLink,
  fileDeleteLink,
  editing,
}) => {
  const currentFormState = useCallbackDependencies(callbacks);
  const { toggle } = useExpandAccordions(sectionConfig);

  const sectionElements = useMemo(
    () =>
      sectionConfig.map(({ title, fields: fieldKeys }) => ({
        title,
        elements: fieldKeys.map((fieldKey) => {
          let {
            type,
            label,
            shortLabel,
            description,
            validationRules,
            value: _value,
            hidden,
            ...props
          } = currentFormState[fieldKey];

          if (hidden) {
            return null;
          }

          let element;
          switch (type) {
            case FieldType.TABLE:
              const tableProps = props as Omit<TableConfigs, keyof BasicConfigs>;
              element = (
                <Table
                  editing={editing}
                  fieldKey={fieldKey}
                  name={`${fieldKey}.value`}
                  validationRules={validationRules}
                  {...tableProps}
                />
              );
              break;

            default:
              element = (
                <FieldElement
                  editing={editing}
                  fieldKey={fieldKey}
                  name={`${fieldKey}.value`}
                  type={type}
                  className={
                    _.includes([FieldType.TEXT, FieldType.SELECTION], type)
                      ? "w-100-md-50"
                      : "w-100"
                  }
                  validationRules={validationRules}
                  {...{ fileUploadLink, fileDeleteLink }}
                  {...props}
                />
              );
          }

          return (
            <FieldLayout
              key={fieldKey}
              required={validationRules?.includes("required")}
              {...{ label, description, element }}
            />
          );
        }),
      })),
    [currentFormState]
  );

  return (
    <>
      {sectionElements.map(({ title, elements }, sectionIdx) => (
        <Accordion key={sectionIdx}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => toggle(title)}>
            <Typography variant="h6" className="title">
              Section {toRoman(sectionIdx + 1)}: {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{elements}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

interface AppFormBodyProps extends FormBodyProps {
  formMethods: UseFormReturn<FormState>;
  allErrorsVisible?: boolean;
}

const AppFormBody: React.FC<AppFormBodyProps> = ({
  formMethods,
  allErrorsVisible,
  ...formBodyProps
}) => {
  const validator = useRef(new Validator());

  return (
    <FormProvider {...formMethods}>
      <ValidationProvider value={{ validator, allErrorsVisible }}>
        <FormBody {...formBodyProps} />
      </ValidationProvider>
    </FormProvider>
  );
};

export default AppFormBody;
export { useFormState };
