import axios, { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Message } from "semantic-ui-react";
import { useImmer } from "use-immer";
import Validator from "../../../util/validator";
import Body from "./components/Body";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Credentials } from "./components/Inputs";
import StatusMessage, { MessageStatus, Status } from "./components/StatusMessage";
import "./index.css";
import { Action, AuthPageMode } from "./states";

interface AuthPageProps {
  mode: AuthPageMode;
  renewSession?: (expires: string) => void;
}

const useAuth = (props: AuthPageProps) => {
  const { mode: startMode, renewSession } = props;

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<AuthPageMode>(startMode);
  const [status, setStatus] = useState<Status>({});
  const [credentials, setCredentials] = useImmer<Credentials>({});
  const [allErrorsShown, setAllErrorsShown] = useState(false);

  const { verificationToken, resetPasswordToken } = useParams<{
    verificationToken?: string;
    resetPasswordToken?: string;
  }>();

  const validator = useRef(new Validator());

  const switchModeHandler = (mode: AuthPageMode) => {
    validator.current.purgeFields();
    setStatus({});
    setMode(mode);
  };

  const authHandler = (action: Action) => {
    if (action === Action.SIGNUP || action === Action.RESET_PASSWORD) {
      if (!validator.current.allValid()) {
        validator.current.showMessages();
        setAllErrorsShown(true);
        return;
      }
    }
    setLoading(true);

    type AuthResponseData = string | { message: string; payload?: { description?: string } };
    let response: Promise<AxiosResponse<AuthResponseData>>;
    switch (action) {
      case Action.LOGIN:
        response = axios
          .post("/apply/auth/login", {
            email: credentials.email,
            password: credentials.password,
          })
          .then((response) => {
            renewSession!(response.data.expires);
            return response;
          });
        break;

      case Action.SIGNUP:
        response = axios
          .post("/apply/auth/register", {
            email: credentials.email,
            password: credentials.password,
          })
          .then((response) => {
            setMode(AuthPageMode.SIGNUP_ACCEPTED);
            return response;
          });
        break;

      case Action.VERIFY_EMAIL:
        response = axios
          .post(`/apply/auth/verify-email/${verificationToken}`)
          .then((response) => {
            setMode(AuthPageMode.VERIFICATION_SUCCESS);
            return response;
          })
          .catch((error) => {
            setMode(AuthPageMode.VERIFICATION_ERROR);
            throw error;
          });
        break;

      case Action.INITIATE_PASSWORD_RESET:
        response = axios
          .post("/apply/auth/password-reset-request", { email: credentials.email })
          .then((response) => {
            setMode(AuthPageMode.PASSWORD_RESET_INITIATED);
            return response;
          });
        break;

      case Action.RESET_PASSWORD:
        response = axios
          .post(`/apply/auth/reset-password/${resetPasswordToken}`, {
            password: credentials.password,
          })
          .then((response) => {
            setMode(AuthPageMode.RESET_PASSWORD_ACCEPTED);
            return response;
          });
        break;

      default:
        const _exhaustiveCheck: never = action;
        throw new Error(`Unhandled action: ${action}`);
    }

    return response
      .then((response) => ({
        status: MessageStatus.SUCCESS,
        data: response.data,
        error: null,
      }))
      .catch((error: AxiosError<AuthResponseData>) => ({
        status: MessageStatus.ERROR,
        data: error?.response?.data,
        error,
      }))
      .then(({ status, data, error }) => {
        let title: string | undefined;
        let description: string | null | undefined;
        if (typeof data === "string") {
          title = data;
        } else if (typeof data === "object" && data?.message) {
          title = data.message;
          description = data?.payload?.description;
        } else if (status === MessageStatus.ERROR) {
          title = error ? error?.toString() : "An error occurred";
          description = "Please contact site administrator for assistance.";
        }

        setStatus({
          status,
          title,
          description,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (mode === AuthPageMode.VERIFY_EMAIL) {
      authHandler(Action.VERIFY_EMAIL);
    }
  }, []);

  return {
    mode,
    status,
    loading,
    credentials,
    setCredentials,
    validator,
    allErrorsShown,
    switchModeHandler,
    authHandler,
  };
};

const useSubmitWithEnterKey = () => {
  const buttonRef = useRef<Button>() as React.MutableRefObject<Button>;
  const onKeyPress = ({ key }: { key: string }) => {
    if (key === "Enter") {
      buttonRef.current?.props.onClick?.(null, null);
    }
  };
  return { buttonRef, onKeyPress };
};

const AuthPage: React.FC<AuthPageProps> = (props) => {
  const {
    mode,
    status,
    loading,
    credentials,
    setCredentials,
    validator,
    allErrorsShown,
    switchModeHandler,
    authHandler,
  } = useAuth(props);

  const { buttonRef, onKeyPress } = useSubmitWithEnterKey();

  return (
    <div className="vh-100 vw-100 d-flex bg-light">
      <Card className="mx-auto my-auto p-3 login-form" onKeyPress={onKeyPress}>
        <Card.Header>
          <StatusMessage {...status} />
        </Card.Header>
        <Card.Content className="border-top-0">
          <Header {...{ mode, switchModeHandler }} />
          <Body {...{ mode, credentials, setCredentials, validator, allErrorsShown }} />
        </Card.Content>
        <Card.Content className="border-top-0">
          <Footer {...{ mode, switchModeHandler, loading, buttonRef, authHandler }} />
        </Card.Content>
        <Card.Content className="border-top-0">
          <Message info>
            If you are facing any technical difficulties, please contact us through our Instagram /
            Facebook inbox.
          </Message>
        </Card.Content>
      </Card>
    </div>
  );
};

export default AuthPage;
