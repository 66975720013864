import { useEffect } from "react";
import { useParams } from "react-router";

const ConfigIdUpdater = ({ setConfigId }: { setConfigId: (configId?: string) => void }) => {
  const { configId } = useParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setConfigId(configId), []);
  return null;
};

export default ConfigIdUpdater;
