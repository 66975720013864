import { useParams } from "react-router-dom";
import useSWR from "swr";
import { RequestError } from "../../types/Error";
import { fetcher } from "../../util/request";
import * as R from "ramda";
import { AppFormConfigGeneratorContext } from "./../../views/admin/appFormConfigs/types";
import { ProcessFormConfigGeneratorContext } from "../../views/admin/processFormConfigs/types";
import Application from "../../types/admin/Application";

export type SerializeConfig<Config, Context> = Omit<Config, "callbacks"> & {
  serializedCallbackGenerator: string;
  context: Context;
};

interface SerializedApplication extends Omit<Application, "appFormConfig" | "processFormConfig"> {
  appFormConfig: SerializeConfig<Application["appFormConfig"], AppFormConfigGeneratorContext>;
  processFormConfig: SerializeConfig<
    Application["processFormConfig"],
    ProcessFormConfigGeneratorContext
  >;
}

export function parseConfigGenerator(functionDefinition: string) {
  // eslint-disable-next-line no-eval
  return eval(`(${functionDefinition})`);
}

export const deserializeConfigCallbacks = <Config, Context>(
  config: SerializeConfig<Config, Context>
): Config => {
  const { serializedCallbackGenerator, context, ...remainingConfig } = config;
  return {
    ...remainingConfig,
    callbacks: parseConfigGenerator(serializedCallbackGenerator)(context),
  } as Config;
};

const deserializeCallbacks = (application: SerializedApplication): Application => {
  const appFormConfig = deserializeConfigCallbacks(application.appFormConfig);
  const processFormConfig = deserializeConfigCallbacks(application.processFormConfig);
  return {
    ...application,
    appFormConfig,
    processFormConfig,
  };
};

const useApplication = () => {
  const { id: applicationId } = useParams();
  const {
    data: application,
    error,
    isLoading,
    ...swr
  } = useSWR<Application, RequestError>(
    `/admin/application/${applicationId}`,
    R.compose((data: Promise<SerializedApplication>) => data.then(deserializeCallbacks), fetcher)
  );

  return { application, applicationId, error, isLoading, ...swr };
};

export default useApplication;
