import { message } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import { FormProvider } from "react-hook-form";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useAsyncFn, useToggle } from "react-use";
import useSWR from "swr";
import ErrorBoundary from "../../components/wrappers/ErrorBoundary";
import Loading from "../../components/wrappers/Loading";
import { RequestError } from "../../types/Error";
import { fetcher } from "../../util/request";
import Buttons from "./configManager/components/Buttons";
import ConfigEditer from "./configManager/components/ConfigEditer";
import ConfigIdUpdater from "./configManager/components/ConfigIdUpdater";
import Picker from "./configManager/components/Picker";
import Previewer from "./configManager/components/Previewer";
import useConfig from "./configManager/hooks/useConfig";
import useCurrentConfigId from "./configManager/hooks/useCurrentConfigId";
import useDraft from "./configManager/hooks/useDraft";
import {
  ConfigVariableList,
  ConfigVariables,
  ExtractStringKeys,
  Stringify,
} from "./configManager/types";

// TODO Write a tutorial manual (e.g. what would undefined mean as a callback result? ans: value unchanged)

// TODO: Route: Delay rendering, see how much performance improves

export interface FormConfigOverview {
  id: string;
  cycle: string;
  timestamp: string;
}

export interface ConfigManagerProps<
  SerializedConfig extends { id: string; timestamp: string },
  InputtedConfig extends Record<ExtractStringKeys<SerializedConfig>, any>,
> {
  endpoint: "app-form-config" | "process-form-config";
  configVariables: ConfigVariables<ExtractStringKeys<SerializedConfig>>;
  prepareConfigForInput: (config: Stringify<SerializedConfig>) => InputtedConfig;
  prepareConfigForUpload: (config: InputtedConfig) => SerializedConfig;
  previewerComponent: React.FC<{ values?: InputtedConfig; editing: boolean }>;
}

function ConfigManager<
  SerializedConfig extends { id: string; timestamp: string },
  InputtedConfig extends Record<ExtractStringKeys<SerializedConfig>, any>,
>({
  endpoint,
  configVariables,
  prepareConfigForInput,
  prepareConfigForUpload,
  previewerComponent,
}: ConfigManagerProps<SerializedConfig, InputtedConfig>) {
  const {
    data: configs,
    error: configsError,
    isLoading: overviewLoading,
    mutate: mutateConfigs,
  } = useSWR<FormConfigOverview[], RequestError>(`/admin/${endpoint}/list`, fetcher);

  const { configId, setConfigId } = useCurrentConfigId(configs);
  const useConfigOptions = { endpoint, configVariables, prepareConfigForInput };
  const {
    config,
    error: configError,
    isLoading: configLoading,
    mutate: mutateConfig,
  } = useConfig(configId, useConfigOptions);
  const { formMethods, draft: draftConfig } = useDraft<InputtedConfig>(config);

  const navigate = useNavigate();

  const [{ loading: editLoading }, onConfigEdit] = useAsyncFn(async (config: InputtedConfig) => {
    const { id, timestamp, ...editedConfig } = prepareConfigForUpload(config);
    return axios
      .patch(`/admin/${endpoint}/${id}`, editedConfig)
      .then(() => {
        mutateConfig();
        navigate("");
        message.success("Form config has been edited.");
      })
      .catch(() => message.error("Form config cannot be edited. Please try again."));
  });

  const [{ loading: createLoading }, onConfigCreate] = useAsyncFn(
    async (config: InputtedConfig) => {
      const { id, timestamp, ...newConfig } = prepareConfigForUpload(config);
      return axios
        .put(`/admin/${endpoint}`, newConfig)
        .then(() => {
          mutateConfigs();
          navigate("");
          message.success("A new form config has beeen created.");
        })
        .catch(() => message.error("Form config cannot be created. Please try again."));
    }
  );

  const [allowUnsafeEdits, toggleUnsafeEdits] = useToggle(false);
  const configVariableList = _.toPairs(configVariables).map(([key, value]) => ({
    key,
    ...value,
    editable: value.editable || allowUnsafeEdits,
  })) as ConfigVariableList<ExtractStringKeys<SerializedConfig>>;

  return (
    <Loading loading={false} error={configsError}>
      <div className="d-flex align-items-center">
        <Picker
          loading={overviewLoading}
          options={(configs ?? []).map(({ id, timestamp }, index) => ({
            label: `Version ${index + 1}: ${dayjs(timestamp).format()}`,
            value: id,
          }))}
          value={configId}
          onChange={(id) => {
            setConfigId(id);
            navigate("");
          }}
        />
        <Buttons
          {...{
            loading: overviewLoading,
            id: configId,
            values: config,
            formMethods,
            allowUnsafeEdits,
            toggleUnsafeEdits,
            onSubmitEdit: formMethods.handleSubmit(onConfigEdit),
            onSubmitCreate: formMethods.handleSubmit(onConfigCreate),
            submitting: editLoading || createLoading,
          }}
        />
      </div>
      <FormProvider {...formMethods}>
        {overviewLoading ? null : (
          <Loading loading={configLoading} error={configError}>
            <ErrorBoundary>
              <Routes>
                <Route
                  path="*"
                  element={<ConfigEditer editing={false} {...{ configVariableList }} />}
                />
                <Route
                  path=":configId/*"
                  element={
                    <>
                      <ConfigIdUpdater {...{ setConfigId }} />
                      <Routes>
                        <Route
                          path="preview"
                          element={
                            <Previewer
                              editing={false}
                              values={config}
                              component={previewerComponent}
                            />
                          }
                        />
                        <Route path="edit">
                          <Route
                            path=""
                            element={<ConfigEditer editing {...{ configVariableList }} />}
                          />
                          <Route
                            path="preview"
                            element={
                              <Previewer
                                editing
                                values={draftConfig ?? config}
                                component={previewerComponent}
                              />
                            }
                          />
                        </Route>
                      </Routes>
                    </>
                  }
                />
                <Route path="create">
                  <Route
                    path=""
                    element={<ConfigEditer editing isNew {...{ configVariableList }} />}
                  />
                  <Route
                    path="preview"
                    element={
                      <Previewer
                        editing
                        values={draftConfig ?? config}
                        component={previewerComponent}
                      />
                    }
                  />
                </Route>
              </Routes>
            </ErrorBoundary>
          </Loading>
        )}
      </FormProvider>
    </Loading>
  );
}

export default ConfigManager;
