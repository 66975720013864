import { useContext } from "react";
import { AuthContext } from "./withAuth";

import NavigationBarComponent from "../../components/aahk/NavigationBar";

export const NAVIGATION_PAGES = [
  { path: "applications", name: "Applications" },
  // { path: "aptitude-tests", name: "Aptitude tests" },
  // { path: "interviews", name: "Interviews" },
];

const NavigationBar: React.FC = () => {
  const { logout } = useContext(AuthContext);
  return <NavigationBarComponent menu={NAVIGATION_PAGES} logout={logout} />;
};

export default NavigationBar;
