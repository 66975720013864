import Error500 from "../../views/errors/Error500";
import UnauthorizedError from "../../views/errors/UnauthorizedError";
import BeatLoader from "../loaders/BeatLoader";

const Loading: React.FC<
  React.PropsWithChildren<{
    loading: boolean;
    error?: any;
  }>
> = ({ loading, error, children }) => {
  if (loading) {
    return <BeatLoader size="50" color="purple" />;
  }
  if (error) {
    if (error?.response?.status === 401) {
      return <UnauthorizedError />;
    }
    return <Error500 />;
  }
  return <>{children}</>;
};

export default Loading;
