import { Icon } from "semantic-ui-react";
import SimpleReactValidator from "simple-react-validator";
import { Updater } from "use-immer";
import { ValidatedText } from "./../../../../components/inputs/index";

export interface Credentials {
  email?: string;
  password?: string;
  confirmPassword?: string;
}

export interface AuthInputProps {
  credentials: Credentials;
  setCredentials: Updater<Credentials>;
  validator: React.MutableRefObject<SimpleReactValidator>;
  validate: boolean;
  mustShowError: boolean;
}

export const Email: React.FC<AuthInputProps> = ({
  credentials,
  setCredentials,
  validator,
  validate,
  mustShowError,
}) => (
  <div className="mt-4">
    <ValidatedText
      icon="user"
      iconPosition="left"
      label="Email"
      labelPosition="top"
      size="large"
      className="w-100"
      value={credentials.email}
      onChange={(value) =>
        setCredentials((credentials) => {
          credentials.email = value;
        })
      }
      validation={
        validate
          ? {
              validator,
              id: "email",
              rules: "required|email",
              mustShowError,
            }
          : undefined
      }
    />
  </div>
);

export const Password: React.FC<AuthInputProps> = ({
  credentials,
  setCredentials,
  validator,
  validate,
  mustShowError,
}: AuthInputProps) => (
  <div className="mt-4">
    <ValidatedText
      icon="lock"
      iconPosition="left"
      label="Password"
      labelPosition="top"
      type="password"
      size="large"
      className="w-100"
      value={credentials.password}
      onChange={(value) =>
        setCredentials((credentials) => {
          credentials.password = value;
        })
      }
      validation={
        validate
          ? {
              validator,
              id: "password",
              rules: "required|min:8,string",
              mustShowError,
            }
          : undefined
      }
    />
  </div>
);

export const ConfirmPassword: React.FC<AuthInputProps> = ({
  credentials,
  setCredentials,
  validator,
  validate,
  mustShowError,
}: AuthInputProps) => (
  <div className="mt-4">
    <ValidatedText
      icon={<Icon as="i" className="far fa-check-circle" />}
      iconPosition="left"
      label="Confirm password"
      labelPosition="top"
      type="password"
      size="large"
      className="w-100"
      value={credentials.confirmPassword}
      onChange={(value) =>
        setCredentials((credentials) => {
          credentials.confirmPassword = value;
        })
      }
      validation={
        validate
          ? {
              validator,
              id: "confirmPassword",
              rules: "required|matchPassword",
              options: {
                validators: {
                  matchPassword: {
                    message: "The :attribute must match the password.",
                    rule: (currentValue, params, validator) =>
                      currentValue === credentials.password,
                    required: true,
                  },
                },
              },
              mustShowError,
            }
          : undefined
      }
    />
  </div>
);
