import _ from "lodash";
import {
  InputtedProcessFormConfig,
  ProcessFormConfig,
  ProcessFormConfigGeneratorContext,
} from "./types";
import { fetcher } from "../../../util/request";
import useSWR from "swr";
import { RequestError } from "../../../types/Error";
import { FormBodyConfig } from "../../../types/FormBodyConfig";
import useFormState from "../../../components/aahk/appFormBody/hooks/useFormState";
import AppFormBody from "../../../components/aahk/AppFormBody";
import Loading from "../../../components/wrappers/Loading";
import BeatLoader from "../../../components/loaders/BeatLoader";

interface ProcessFormPreviewerProps {
  values?: InputtedProcessFormConfig;
}

// eslint-disable-next-line no-eval
const parseStringIntoJSObject = (value: string) => eval(`(${value})`);

const compile = ({
  id,
  timestamp,
  serializedQuestionConfigGenerator,
  serializedCallbackGenerator,
  sectionConfig,
}: InputtedProcessFormConfig): ProcessFormConfig => {
  return {
    id,
    timestamp,
    questionConfigGenerator: parseStringIntoJSObject(serializedQuestionConfigGenerator),
    callbackGenerator: parseStringIntoJSObject(serializedCallbackGenerator),
    sectionConfig: parseStringIntoJSObject(sectionConfig),
  };
};

const generate = (
  config: ProcessFormConfig,
  context: ProcessFormConfigGeneratorContext
): FormBodyConfig => {
  return {
    questionConfig: config.questionConfigGenerator(context),
    sectionConfig: config.sectionConfig,
    callbacks: config.callbackGenerator(context),
  };
};

const ProcessFormPreviewer: React.FC<ProcessFormPreviewerProps> = ({ values: config }) => {
  const {
    data: context,
    error,
    isLoading,
  } = useSWR<ProcessFormConfigGeneratorContext, RequestError>(
    "/admin/process-form-config/context",
    fetcher
  );

  const formBodyConfig =
    config !== undefined && context !== undefined ? generate(compile(config), context) : undefined;
  const formMethods = useFormState(formBodyConfig, {});

  if (formBodyConfig === undefined || _.isEmpty(formMethods.getValues())) {
    return <BeatLoader />;
  }

  return (
    <Loading loading={isLoading} error={error}>
      <AppFormBody
        {...{
          formMethods,
          sectionConfig: formBodyConfig.sectionConfig,
          callbacks: formBodyConfig.callbacks,
        }}
      />
    </Loading>
  );
};
export default ProcessFormPreviewer;
