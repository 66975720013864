import { FormControlLabel, Switch } from "@mui/material";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { Pagination, Select, message } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import Loading from "../../components/wrappers/Loading";
import { FRONTEND_BASEURL, UNI_ABBREVIATIONS } from "../../constants";
import { fetcherWithOptions } from "../../util/request";
import useInvisibleLinks from "./../../hooks/useInvisibleLinks";
import * as R from "ramda";

interface SimplifiedApplication extends Record<string, unknown> {
  id: string;
  email: string;
}

const DEFAULT_FIELDS = [
  "email",
  "preferredName",
  "surname",
  "firstUniChoice",
  "firstSubjectChoice",
  "secondUniChoice",
  "secondSubjectChoice",
  "id",
];

const DEFAULT_COLUMN_DEFS: Record<string, any> = {
  email: {
    width: 250,
  },
  preferredName: {
    width: 120,
  },
  surname: {
    width: 100,
  },
  firstUniChoice: {
    headerName: "U1",
    width: 100,
  },
  secondUniChoice: {
    headerName: "U2",
    width: 100,
  },
};

interface PageParams {
  fields: string[];
  page: number;
  pageSize: number;
  unsubmitted: boolean;
  setAvailableFields?: (fields: string[]) => void;
}

const ApplicationList: React.FC<PageParams> = ({
  fields,
  page,
  pageSize,
  unsubmitted,
  setAvailableFields,
}) => {
  const { data: applications, error } = useSWR<SimplifiedApplication[]>(
    [`/admin/application/list`, { params: { page, pageSize, unsubmitted } }],
    fetcherWithOptions
  );

  useEffect(() => {
    if (setAvailableFields !== undefined && applications) {
      const fields = _.union(...applications.map(Object.keys));
      setAvailableFields?.(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications]);

  useEffect(() => {
    if (error) message.error("Cannot fetch applications. Please try again.");
  }, [error]);

  const columnDefs = useMemo(
    () =>
      fields.map((field) => {
        return {
          ...DEFAULT_COLUMN_DEFS[field],
          field,
        } as any;
      }),
    [fields]
  );

  const { invisibleLinks, openLink } = useInvisibleLinks<string>(
    R.mergeAll(
      (applications ?? []).map((application) => ({
        [application.id]: `${FRONTEND_BASEURL}/admin/application/${application.id}`,
      }))
    )
  );

  const applicationsOverview = applications?.map((application) => ({
    ...application,
    firstUniChoice: UNI_ABBREVIATIONS[application.firstUniChoice as string],
    secondUniChoice: UNI_ABBREVIATIONS[application.secondUniChoice as string],
  }));

  return (
    <div className="w-100 ag-theme-alpine" style={{ height: "calc(100vh - 14rem)" }}>
      {invisibleLinks}
      <AgGridReact
        columnDefs={columnDefs}
        enableRangeSelection={true}
        defaultColDef={{
          resizable: true,
          filter: true,
          width: 250,
          sortable: true,
        }}
        rowData={applicationsOverview}
        onRowClicked={(e) => openLink(e.data?.id)()}
      />
    </div>
  );
};

interface FieldSelectorProps {
  fields: string[];
  setFields: (fields: string[]) => void;
  availableFields: string[];
}

const FieldSelector: React.FC<FieldSelectorProps> = ({ fields, setFields, availableFields }) => {
  return (
    <Select
      mode="multiple"
      style={{ width: "100%" }}
      placeholder="Please select visible fields"
      defaultValue={fields}
      onChange={(fields) => setFields(fields)}
    >
      {availableFields.map((field) => (
        <Select.Option key={field}>{field}</Select.Option>
      ))}
    </Select>
  );
};

const Applications: React.FC = () => {
  const [fields, setFields] = useState(DEFAULT_FIELDS);
  const [availableFields, setAvailableFields] = useState<string[]>([]);
  const [viewUnsubmitted, setViewUnsubmitted] = useState(false);

  const {
    data: total,
    error,
    isLoading,
  } = useSWR<number>(
    [`/admin/application/count`, { params: { unsubmitted: viewUnsubmitted } }],
    fetcherWithOptions
  );

  const [{ page, pageSize }, setPageParams] = useState({
    page: 1,
    pageSize: 20,
  });

  return (
    <>
      <FieldSelector {...{ fields, setFields, availableFields }} />
      <div className="d-flex flex-column align-items-center mt-3 gap-3">
        <div className="d-flex justify-content-between w-100">
          <div>
            <Pagination
              showSizeChanger
              showQuickJumper
              current={page}
              pageSize={pageSize}
              onChange={(page, pageSize) => setPageParams({ page, pageSize })}
              total={total}
              pageSizeOptions={[20, 50, 100, 10000]}
              responsive
            />
          </div>
          <FormControlLabel
            control={<Switch checked={viewUnsubmitted} />}
            label="View Unsubmitted"
            onChange={(event, checked) => setViewUnsubmitted(checked)}
            className="mx-4"
          />
        </div>
        <Loading loading={isLoading} error={error}>
          <ApplicationList
            fields={fields}
            {...{ page, pageSize, unsubmitted: viewUnsubmitted, setAvailableFields }}
          />
          {/* The following hidden snippet prefetches the next page */}
          <div className="d-none">
            <ApplicationList
              fields={fields}
              {...{ page: page + 1, pageSize, unsubmitted: viewUnsubmitted }}
            />
          </div>
        </Loading>
      </div>
    </>
  );
};

export default Applications;
