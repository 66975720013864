import { UploadFile, message } from "antd";
import axios, { AxiosResponse } from "axios";
import { Controller, useFormContext } from "react-hook-form";
import ErrorMessage from "../../../../components/form/ErrorMessage";
import {
  Checkbox,
  DatePicker,
  Radio,
  Selection,
  Text,
  Textarea,
  Upload,
} from "../../../../components/inputs";
import {
  FieldType,
  SingleConfig,
  SingleFields,
} from "../../../../types/formBodyConfig/QuestionConfig";
import { FormState } from "../util";
import { useValidationContext } from "./ValidationContext";

export const inputElements = {
  [FieldType.TEXT]: Text,
  [FieldType.SELECTION]: Selection,
  [FieldType.TEXTAREA]: Textarea,
  [FieldType.RADIO]: Radio,
  [FieldType.CHECKBOX]: Checkbox,
  [FieldType.FILES]: Upload,
  [FieldType.DATE]: DatePicker,
} as const;

type FieldElementProps<T extends SingleFields> = Pick<
  SingleConfig<T>,
  "type" | "validationRules"
> & {
  fieldKey: string;
  name: string;
  editing?: boolean;
  className?: string;
  fileUploadLink?: string;
  fileDeleteLink?: string;
};

const FieldElement: React.FC<FieldElementProps<SingleFields>> = <T extends SingleFields>(
  props: FieldElementProps<T>
) => {
  const { control, getFieldState } = useFormContext<FormState>();
  const { validator } = useValidationContext();
  let {
    fieldKey,
    name,
    type,
    className,
    validationRules,
    editing = true,
    fileUploadLink,
    fileDeleteLink,
    ...elementProps
  } = props;

  if (type === FieldType.FILES) {
    elementProps = {
      ...elementProps,
      multiple: true,
      onUpload: (file: UploadFile & Required<Pick<UploadFile, "originFileObj">>) => {
        if (fileUploadLink === undefined) {
          return;
        }
        const formData = new FormData();
        formData.append("file", file.originFileObj);
        formData.append("field", fieldKey);
        return axios
          .patch(fileUploadLink, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response: AxiosResponse<{ name: string; url: string }>) => response.data)
          .catch((error) => {
            message.error("Upload failed. Please try again.");
            throw error;
          });
      },
      onDelete: (file: UploadFile) => {
        if (fileDeleteLink === undefined) {
          return;
        }
        return axios
          .patch(fileDeleteLink, {
            field: fieldKey,
            filename: file.name,
          })
          .catch((error) => {
            message.error("File has not been removed. Please try again.");
            throw error;
          });
      },
    };
  }

  const Element = inputElements[type];
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Element
            {...elementProps}
            {...field}
            editing={editing}
            className={className}
            error={getFieldState(name).error !== undefined}
          />
        )}
        rules={validator?.current.getOptions(fieldKey, validationRules)}
      />
      <ErrorMessage name={name} />
    </>
  );
};

export default FieldElement;
