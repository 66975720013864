import Loading from "../../../components/wrappers/Loading";
import useApplication from "../../../hooks/apply/useApplication";
import Error404 from "../../errors/Error404";
import ApplicationReceived from "./components/ApplicationReceived";

const Application: React.FC = () => {
  const { application, isLoading, error } = useApplication();

  if (error) {
    return <Error404 mode="apply" />;
  }

  return (
    <Loading loading={isLoading}>
      <ApplicationReceived />
    </Loading>
  );
};

export default Application;
