export enum AuthPageMode {
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  SIGNUP_ACCEPTED = "SIGNUP_ACCEPTED",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS",
  VERIFICATION_ERROR = "VERIFICATION_ERROR",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  PASSWORD_RESET_INITIATED = "PASSWORD_RESET_INITIATED",
  RESET_PASSWORD = "RESET_PASSWORD",
  RESET_PASSWORD_ACCEPTED = "RESET_PASSWORD_ACCEPTED",
}

export enum Action {
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  INITIATE_PASSWORD_RESET = "INITIATE_PASSWORD_RESET",
  RESET_PASSWORD = "RESET_PASSWORD",
}
