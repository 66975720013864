import { message } from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { Button, Icon, Message } from "semantic-ui-react";
import AppFormBody, { useFormState } from "../../../components/aahk/AppFormBody";
import BeatLoader from "../../../components/loaders/BeatLoader";
import ErrorBoundary from "../../../components/wrappers/ErrorBoundary";
import useApplication from "../../../hooks/apply/useApplication";
import useUnsafeReloadPrompt from "../../../hooks/useUnsafeReloadPrompt";
import Application from "../../../types/apply/Application";
import ErrorMessageList from "./ErrorMessageList";

const useApplicationForm = () => {
  const { application, applicationId, error, isLoading: loadingApplication } = useApplication();
  const formMethods = useFormState(application?.appFormConfig, application?.savedForm);

  useEffect(() => {
    if (loadingApplication) return;

    if (!application?.submitted) {
      if (!error && application?.savedForm) {
        message.success("Progress has been restored.");
        return;
      }
      if (error) {
        switch (error.response?.data.message) {
          case "Application does not exist":
            message.error("Application does not exist");
            break;

          default:
            message.error("Cannot restore progress. Please try again.");
        }
      }
    }
  }, [loadingApplication]);

  let applicationWithoutForm: Omit<Application, "savedForm"> | undefined;
  if (application === undefined) {
    applicationWithoutForm = application;
  } else {
    const { savedForm, ...metadata } = application;
    applicationWithoutForm = metadata;
  }

  return {
    application: applicationWithoutForm,
    applicationId,
    formMethods,
    isLoading:
      loadingApplication || (application !== undefined && _.isEmpty(formMethods.getValues())),
    // loading application  || loading useAppFormState
  };
};

const useValidation = () => {
  const [allErrorsVisible, setAllErrorsVisible] = useState(false);
  const [scrollToErrorCount, setScrollToErrorCount] = useState(0);
  const errorMessageRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (scrollToErrorCount) {
      errorMessageRef.current?.scrollIntoView();
    }
  }, [scrollToErrorCount]);

  return {
    allErrorsVisible,
    setAllErrorsVisible,
    scrollToErrorMessage: () => setScrollToErrorCount((count) => count + 1),
    errorMessageRef,
  };
};

const useAppFormState = () => {
  const { application, applicationId, formMethods, isLoading } = useApplicationForm();

  const setUnsavedChanges = useUnsafeReloadPrompt();
  useEffect(() => {
    setUnsavedChanges(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty]);

  const { allErrorsVisible, setAllErrorsVisible, scrollToErrorMessage, errorMessageRef } =
    useValidation();

  const navigate = useNavigate();

  const [{ loading: savingProgress }, saveProgress] = useAsyncFn(async () => {
    const form = formMethods.getValues();
    const formValues = Object.entries(form).reduce(
      (values, [fieldKey, { value }]) => ({ ...values, [fieldKey]: value }),
      {}
    );

    const promise = await axios
      .patch(`/apply/application/save/${applicationId}`, formValues)
      .then((response) => message.success("Application has been saved."))
      .catch((error) => message.error("Progress save is unsuccessful. Please try again."));

    formMethods.reset({}, { keepValues: true, keepErrors: true, keepIsValid: true });
    return promise;
  }, [applicationId, formMethods]);

  const [{ loading: submitting }, submit] = useAsyncFn(async () => {
    const isValid = await formMethods.trigger();
    if (!isValid) {
      setAllErrorsVisible(true);
      scrollToErrorMessage();
      return;
    }
    return saveProgress()
      .then(() => axios.post(`/apply/application/submit/${applicationId}`))
      .then(() => navigate(`/apply/application/${applicationId}`))
      .catch((error) => message.error("Submission is unsuccessful. Please try again."));
  }, [saveProgress, applicationId]);

  return {
    application,
    applicationId,
    allErrorsVisible,
    errorMessageRef,
    formMethods,
    isLoading,
    savingProgress,
    saveProgress,
    submitting,
    submit,
  };
};

const AppForm: React.FC = () => {
  const {
    application,
    applicationId,
    allErrorsVisible,
    errorMessageRef,
    formMethods,
    isLoading,
    savingProgress,
    saveProgress,
    submitting,
    submit,
  } = useAppFormState();
  
  const navigate = useNavigate();

  if (isLoading) {
    return <BeatLoader />;
  }

  if (application === undefined) {
    navigate("/apply/applications");
    return null;
  }

  if (application.submitted) {
    navigate(`/apply/application/${applicationId}`);
    return null;
  }

  return (
    <>
      <h2>Application Form ({application.cycle} Admission)</h2>
      <Message warning icon>
        <Icon name="warning sign" />
        <Message.Content>
          <Message.Header>Please save your progress regularly to avoid data loss.</Message.Header>
          <p>
            You will be automatically logged out after 1 hour of inactivity for security reasons,
            and unsaved data may be lost.
          </p>
        </Message.Content>
      </Message>
      <div ref={errorMessageRef} className="mb-3">
        <ErrorMessageList errors={formMethods.formState.errors} hidden={!allErrorsVisible} />
      </div>
      <ErrorBoundary>
        <AppFormBody
          {...{
            formMethods,
            allErrorsVisible,
            sectionConfig: application.appFormConfig.sectionConfig,
            callbacks: application.appFormConfig.callbacks,
            fileUploadLink: `/apply/application/append-file/${applicationId}`,
            fileDeleteLink: `/apply/application/remove-file/${applicationId}`,
          }}
        />
        <div className="my-3">
          <Button color="green" className="me-4" loading={submitting} onClick={submit}>
            Submit
          </Button>
          <Button onClick={saveProgress} loading={savingProgress}>
            Save Progress
          </Button>
        </div>
      </ErrorBoundary>
    </>
  );
};

export default AppForm;
