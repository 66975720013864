import { Icon } from "semantic-ui-react";
import SimpleReactValidator from "simple-react-validator";

type IObject = Record<string, any>;

class Validator extends SimpleReactValidator {
  constructor() {
    super({
      element: (message: string) => (
        <div style={{ color: "var(--error-color)" }}>
          <Icon name="exclamation triangle" />
          <span>{message}</span>
        </div>
      ),
    });
  }

  getOptions(field: string, validations?: any, options: IObject = {}): IObject {
    if (validations === undefined || validations === null) {
      return {};
    }
    if (!Array.isArray(validations)) {
      validations = validations.split("|");
    }
    const rules = options.validators ? { ...this.rules, ...options.validators } : this.rules;

    return {
      validate: validations.reduce(
        (obj: IObject, validation: string | IObject) => ({
          ...obj,
          [this.helpers.getValidation(validation)]: (inputValue: any) => {
            const [value, rule, params] = this.helpers.normalizeValues(inputValue, validation);
            let message = this.helpers.message(rule, field, options, rules);
            if (params.length > 0 && rules[rule].hasOwnProperty("messageReplace")) {
              message = rules[rule].messageReplace(message, params);
            }
            return this.helpers.passes(rule, value, params, rules) || message;
          },
        }),
        {}
      ),
    };
  }
}

export default Validator;
