export type MaxWidthContainerProps = React.PropsWithChildren<{
  width?: number;
  centered?: boolean;
  style?: Record<string, unknown>;
}>;

const MaxWidthContainer: React.FC<MaxWidthContainerProps> = ({
  width,
  children,
  centered,
  style,
}) => {
  if (centered) {
    style = { ...style, margin: "0 auto" };
  }
  return <div style={{ maxWidth: `${width ?? 1200}px`, ...style }}>{children}</div>;
};
export default MaxWidthContainer;
