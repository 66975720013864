import * as R from "ramda";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { RequestError } from "../../types/Error";
import Application from "../../types/apply/Application";
import { fetcher } from "../../util/request";
import { AppFormConfigGeneratorContext } from "../../views/admin/appFormConfigs/types";
import { SerializeConfig, deserializeConfigCallbacks } from "../admin/useApplication";

interface SerializedApplication extends Omit<Application, "appFormConfig"> {
  appFormConfig: SerializeConfig<Application["appFormConfig"], AppFormConfigGeneratorContext>;
}

const deserializeCallbacks = (application: SerializedApplication): Application => {
  const appFormConfig = deserializeConfigCallbacks(application.appFormConfig);
  return {
    ...application,
    appFormConfig,
  };
};

const useApplication = () => {
  const { id: applicationId } = useParams();
  const {
    data: application,
    error,
    isLoading,
  } = useSWR<Application, RequestError>(
    `/apply/application/${applicationId}`,
    R.compose((data: Promise<SerializedApplication>) => data.then(deserializeCallbacks), fetcher)
  );

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (error && error.response?.data.message === "Application does not exist") {
  //     message.error("Application does not exist.");
  //     navigate("/apply/applications");
  //   }
  // }, [error]);

  return { application, applicationId, error, isLoading };
};

export default useApplication;
