export interface LabelledOption {
  label: string;
  value: string;
}

export function getLabel(
  options: LabelledOption[],
  value?: string,
  allowCustomOption?: boolean
): string | undefined {
  if (value === undefined) {
    return undefined;
  }
  let selectedOption = options.find((option) => option.value === value)?.label;
  return selectedOption ?? (allowCustomOption ? value : "(erased)");
}

export const getLabels = (
  options: LabelledOption[],
  values?: string[],
  allowCustomOption?: boolean
) =>
  values?.map((value) => {
    return getLabel(options, value, allowCustomOption);
  });

export const defaultLabelRender = (labels?: (string | undefined)[]) => (
  <span>{labels?.filter((label) => label !== undefined).join("; ")}</span>
);

export type Options = string[] | LabelledOption[];

function optionsAreStrings(options: Options): options is string[] {
  return Array.isArray(options) && typeof options[0] == "string";
}

export function standardiseOptions(options: Options) {
  let standardOptions;
  if (optionsAreStrings(options)) {
    standardOptions = options.map((option) => ({ label: option, value: option }));
  } else {
    standardOptions = options;
  }
  return standardOptions;
}

export function containsOnlySpaces(str: string) {
  return /^\s*$/.test(str);
}

export function compareOptions(option1: LabelledOption, option2: LabelledOption) {
  return option1.label.localeCompare(option2.label);
}
