import GenderIcon from "./summary/GenderIcon";

export interface HeaderProps {
  surname: string;
  firstName: string;
  preferredName: string;
  gender: string;
}
export const Header: React.FC<HeaderProps> = ({ surname, firstName, preferredName, gender }) => (
  <div className={"d-flex align-items-center"}>
    <div>
      <h3 className="me-2">{`${surname}, ${firstName} (${preferredName})`}</h3>
    </div>
    <GenderIcon gender={gender} />
  </div>
);
