import { Message } from "semantic-ui-react";

export enum MessageStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface Status {
  status?: MessageStatus;
  title?: string;
  description?: string | null;
}

const StatusMessage = (props: Status) => {
  let { status, title, description } = props;
  if (!status || !title) return null;

  const messageProps = status === MessageStatus.SUCCESS ? { positive: true } : { negative: true };

  if (!title) {
    title = "Unknown Error";
    description = "Please contact site administrator for assistance.";
  }

  return (
    <Message {...messageProps} className="rounded">
      <Message.Header>{title}</Message.Header>
      <Message.Content>{description}</Message.Content>
    </Message>
  );
};

// const StatusMessage = (props: {
//   status: AuthStatus;
//   details?: string | { message: string; description?: string };
// }) => {
//   let { status, details } = props;
//   if (!status) return null;

//   let messageProps;
//   switch (status) {
//     case AuthStatus.LOGOUT:
//     case AuthStatus.SUCCESS:
//       messageProps = { positive: true };
//       break;

//     default:
//       messageProps = { negative: true };
//   }

//   switch (status) {
//     case AuthStatus.LOGOUT:
//       details = { message: "You have successfully logged out." };
//       break;

//     case AuthStatus.TOKEN_EXPIRED:
//       details = {
//         message: "Timeout",
//         description: "Please login again.",
//       };
//       break;

//     default:
//       if (typeof details === "string") {
//         details = { message: details };
//       } else if (!details?.message) {
//         details = {
//           message: "Unknown Error",
//           description: "Please contact site administrator for assistance.",
//         };
//       }
//   }

//   return (
//     <Message {...messageProps} className="rounded">
//       <Message.Header>{details.message}</Message.Header>
//       <Message.Content>{details.description}</Message.Content>
//     </Message>
//   );
// };

export default StatusMessage;
