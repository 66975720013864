import useUnsafeReloadPrompt from "./useUnsafeReloadPrompt";
import { useEffect } from "react";

const useReloadPrompt = (enabled?: boolean) => {
  const setUnsavedChanges = useUnsafeReloadPrompt(enabled);
  useEffect(() => {
    if (enabled !== undefined) {
      setUnsavedChanges(enabled);
    }
  }, [enabled]);
};

export default useReloadPrompt;
