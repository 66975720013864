import _ from "lodash";
import ConfigManager from "../../components/aahk/ConfigManager";
import {
  ConfigVariables,
  ExtractStringKeys,
  Stringify,
} from "../../components/aahk/configManager/types";
import ProcessFormPreviewer from "./processFormConfigs/ProcessFormPreviewer";
import { InputtedProcessFormConfig, SerializedProcessFormConfig } from "./processFormConfigs/types";

const CONFIG_VARIABLES: ConfigVariables<ExtractStringKeys<SerializedProcessFormConfig>> = {
  id: {
    type: "metadata",
    label: "ID",
  },
  timestamp: {
    type: "metadata",
    label: "Timestamp",
  },
  serializedQuestionConfigGenerator: {
    type: "js",
    label: "Fields",
  },
  serializedCallbackGenerator: {
    type: "js",
    label: "Callbacks",
  },
  sectionConfig: {
    type: "js",
    label: "Sections",
    editable: true,
  },
};

const prepareConfigForInput = (
  config: Stringify<SerializedProcessFormConfig>
): InputtedProcessFormConfig => config;

const prepareConfigForUpload = ({
  id,
  timestamp,
  serializedQuestionConfigGenerator,
  ...inputtedFormConfig
}: InputtedProcessFormConfig): SerializedProcessFormConfig => {
  // eslint-disable-next-line no-eval
  const parsedConfig = _.mapValues(inputtedFormConfig, (value) => eval(`(${value})`));
  return {
    id,
    timestamp,
    serializedQuestionConfigGenerator,
    ...parsedConfig,
  };
};

const ProcessFormConfigs = () => (
  <ConfigManager
    endpoint="process-form-config"
    configVariables={CONFIG_VARIABLES}
    {...{ prepareConfigForInput, prepareConfigForUpload }}
    previewerComponent={ProcessFormPreviewer}
  />
);

export default ProcessFormConfigs;
