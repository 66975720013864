import { DatePicker as AntdDatePicker } from "antd";
import { PickerDateProps, PickerProps } from "antd/es/date-picker/generatePicker";
import { PickerRef } from "antd/es/date-picker/generatePicker/interface";
import dayjs, { Dayjs } from "dayjs";
import { PickerMode } from "rc-picker/lib/interface";
import React from "react";

// Conditional type always takes only one branch and is used only for its distributive property.
type Distribute<U> = U extends any
  ? Omit<U, "value" | "onChange" | "format" | "picker"> & {
      value?: Dayjs | dayjs.ConfigType;
      onChange?: (value: Dayjs | null) => void;
      picker?: PickerMode | "datetime";
      format?: string;
      editing?: boolean;
      hidden?: boolean;
    }
  : never;

type DatePickerProps = Distribute<PickerProps<Dayjs>>;

export const DEFAULT_DATE_FORMATS = {
  year: "YYYY",
  quarter: "YYYY-QQ",
  date: "YYYY-MM-DD (dddd)",
  month: "YYYY-MM",
  week: "YYYY-wo",
  time: "HH:mm:ss",
  datetime: "YYYY-MM-DD HH:mm:ss",
};

// TODO Add handling logic for 'error' prop
const DatePicker = React.forwardRef(
  (
    {
      value,
      onChange: inputOnChange,
      format,
      style,
      editing = true,
      hidden = false,
      picker,
      ...props
    }: DatePickerProps,
    ref: PickerRef<PickerProps<Dayjs>>
  ) => {
    if (hidden) {
      return null;
    }

    format = format ?? DEFAULT_DATE_FORMATS[picker ?? "date"];
    picker = picker === "datetime" ? "date" : picker;

    if (editing === false) {
      return <>{value ? dayjs(value).format(format) : undefined}</>;
    }

    let datePickerProps = props;
    if (inputOnChange) {
      datePickerProps = {
        ...datePickerProps,
        onOk: (date: Dayjs) => inputOnChange(date),
        onChange: (_date: Dayjs, dateString: string) => inputOnChange(_date),
      } as PickerDateProps<Dayjs>;
    }

    return (
      <AntdDatePicker
        allowClear
        ref={ref}
        size="large"
        value={value ? dayjs(value) : null}
        picker={picker}
        format={format}
        style={{ borderRadius: "5px", ...style }}
        {...datePickerProps}
      />
    );
  }
);

export default DatePicker;
