export interface FieldLayoutProps {
  label: string;
  description?: string;
  element: JSX.Element;
  required?: boolean;
  hidden?: boolean;
}

const FieldLayout: React.FC<FieldLayoutProps> = ({
  label,
  description,
  element,
  required = false,
  hidden = false,
}) => {
  if (hidden) {
    return null;
  }

  const requiredStar = required ? <span style={{ color: "red" }}>*</span> : null;

  return (
    <div className="my-4">
      <div className="fw-bold mb-2">
        {label} {requiredStar}
      </div>
      <div
        className="text-muted mb-2 fs-6"
        dangerouslySetInnerHTML={{ __html: description as string }}
      ></div>
      <div className="ws-pre-line">{element}</div>
    </div>
  );
};

export default FieldLayout;
