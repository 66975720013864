import { Modal } from "antd";
import axios, { AxiosResponse } from "axios";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useClearCache } from "react-clear-cache";

dayjs.extend(relativeTime);

type PermissionGroup = string;

const getLogoutModalTitle = (logoutTime: Dayjs): string =>
  `Your session will expire in ${logoutTime!.fromNow(true)}. Stay on page?`;

const useAuth = (mode: "apply" | "admin") => {
  const [authenticated, setAuthenticated] = useState<boolean>();
  const [groups, setGroups] = useState<PermissionGroup[] | undefined>();
  const [logoutTime, setLogoutTime] = useState<Dayjs | undefined>();
  const [logoutTimer, setLogoutTimer] = useState<NodeJS.Timeout>();
  const [logoutModalTimer, setLogoutModalTimer] = useState<NodeJS.Timeout>();
  const [logoutModalVisible, setLogoutModalVisible] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [logoutCountdown, setLogoutCountdown] = useState<NodeJS.Timer | null>(null);
  const { emptyCacheStorage } = useClearCache();

  const logout = () => axios.post(`/${mode}/auth/logout`).finally(emptyCacheStorage);

  const renewSession = (expires: string) => {
    clearTimeout(logoutTimer);
    clearTimeout(logoutModalTimer);

    const currentTime = dayjs();
    setLogoutTime(dayjs(expires));
    setLogoutTimer(setTimeout(logout, dayjs(expires).diff(currentTime)));
    setLogoutModalTimer(
      setTimeout(
        () => setLogoutModalVisible(true),
        dayjs(expires).subtract(5, "minutes").diff(currentTime)
      )
    );
    setAuthenticated(true);
  };

  const resumeSession = () =>
    axios
      .get(`/${mode}/resume-session`)
      .then((response: AxiosResponse<{ message: string; expires: string; groups?: PermissionGroup[] }>) => {
        if (mode == 'admin') {
          setGroups(response.data.groups)
        }
        renewSession(response.data.expires);
      })
      .catch((error) => (authenticated ? void logout() : setAuthenticated(false)));

  useEffect(() => {
    if (logoutModalVisible) {
      const logoutModal = Modal.confirm({
        title: getLogoutModalTitle(logoutTime!),
        content: (
          <p>
            When your sesion expires, you will be automatically logged out, and any unsaved edits
            will be lost.
          </p>
        ),
        okText: "Continue",
        okType: "primary",
        onOk: (close) => {
          resumeSession();
          close();
        },
        cancelText: "Logout",
        onCancel: (close) => {
          logout();
          close();
        },
        afterClose: () => {
          setLogoutModalVisible(false);
          setLogoutCountdown((countdown) => {
            clearInterval(countdown!);
            return null;
          });
        },
        width: 600,
      });
      setLogoutCountdown(
        setInterval(() => {
          logoutModal.update({
            title: getLogoutModalTitle(logoutTime!),
          });
        }, 1000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutModalVisible]);

  useEffect(() => {
    resumeSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { groups, authenticated, renewSession, logout };
};

export default useAuth;
