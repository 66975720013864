import { Modal, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router";
import { useAsyncFn } from "react-use";
import { Button, Divider } from "semantic-ui-react";
import Application from "../../../../types/admin/Application";
import { KeyedMutator } from "swr";

interface ActionsProps {
  applicationId?: string;
  application: Application;
  update: KeyedMutator<Application>;
  unsafeActions: boolean;
}

const launchUpgradeConfigModal = (onOk: () => any) => () => {
  Modal.confirm({
    title: "The new version may be incompatible with existing values.",
    content: <h5>Proceed with care.</h5>,
    okText: "Proceed",
    onOk: (close) => {
      onOk();
      close();
    },
    width: 550,
  });
};

const Actions: React.FC<ActionsProps> = ({ applicationId, application, update, unsafeActions }) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ loading: upgradingConfig }, upgradeConfig] = useAsyncFn(
    async (form: "app-form" | "process-form") => {
      return axios
        .patch(`/admin/application/upgrade-config/${applicationId}/${form}`)
        .then(() => {
          message.success(`${form} config has been upgraded to the newest version.`);
          update();
        })
        .catch(() => message.error(`${form} config has not been upgraded. Please try again`));
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ loading: restoringConfig }, restoreConfig] = useAsyncFn(
    async (form: "app-form" | "process-form") => {
      return axios
        .patch(`/admin/application/restore-config/${applicationId}/${form}`)
        .then(() => {
          message.success(`${form} config has been restored to the original version.`);
          update();
        })
        .catch(() => message.error(`${form} config has not been restored. Please try again`));
    }
  );

  const [{ loading: unsubmitting }, unsubmitApplication] = useAsyncFn(async () => {
    return axios
      .patch(`/admin/application/unsubmit/${applicationId}`)
      .then(() => {
        message.success("Application has been unsubmitted.");
        navigate("/admin/applications");
      })
      .catch(() => message.error("Application has not been unsubmitted. Please try again"));
  });

  const [{ loading: archiving }, archiveApplication] = useAsyncFn(async () => {
    return axios
      .post(`/admin/application/archive/${applicationId}`)
      .then(() => {
        message.success("Application has been archived.");
        navigate("/admin/applications");
      })
      .catch(() => message.error("Application has not been archived. Please try again"));
  });

  const [{ loading: deleting }, deleteApplication] = useAsyncFn(async () => {
    return axios
      .delete(`/admin/application/delete/${applicationId}`)
      .then(() => {
        message.success("Application has been deleted.");
        navigate("/admin/applications");
      })
      .catch(() => message.error("Application has not been deleted. Please try again."));
  });

  return (
    <>
      <h3>Versioning (Application Form)</h3>
      <div className="d-flex my-2">
        <Button
          content="Upgrade form config"
          className="mx-2"
          onClick={launchUpgradeConfigModal(() => upgradeConfig("app-form"))}
          disabled={application.metadata.isAppFormVersionNewest}
        />
        <Button
          primary
          content="Restore form config"
          className="mx-2"
          onClick={() => restoreConfig("app-form")}
          disabled={application.metadata.isAppFormVersionOriginal}
        />
      </div>
      <Divider />

      <h3>Versioning (Mentorship Processing Form)</h3>
      <div className="d-flex my-2">
        <Button
          content="Upgrade processing config"
          className="mx-2"
          onClick={launchUpgradeConfigModal(() => upgradeConfig("process-form"))}
          disabled={application.metadata.isProcessFormVersionNewest}
        />
        <Button
          primary
          content="Restore processing config"
          className="mx-2"
          onClick={() => restoreConfig("process-form")}
          disabled={application.metadata.isProcessFormVersionOriginal}
        />
      </div>
      <Divider />

      <h3>Application Management</h3>
      <div className="d-flex">
        <Button
          content="Unsubmit application"
          className="mx-2"
          loading={unsubmitting}
          onClick={() => {
            Modal.confirm({
              title: (
                <h5>This will give the applicant a second chance to edit the application form.</h5>
              ),
              width: 480,
              okText: "Unsubmit application",
              onOk: (close) => {
                unsubmitApplication();
                close();
              },
            });
          }}
        />
        <Button
          content="Archive application"
          className="mx-2"
          loading={archiving}
          onClick={() => {
            Modal.confirm({
              title: <h5>Are you sure?</h5>,
              width: 480,
              okText: "Archive application",
              onOk: (close) => {
                archiveApplication();
                close();
              },
            });
          }}
        />
        {unsafeActions ? (
          <Button
            negative
            content="Delete application"
            className="mx-2"
            loading={deleting}
            onClick={() => {
              Modal.confirm({
                title: <h5>This action is irreversible.</h5>,
                content: (
                  <div>
                    <h5>
                      This application cannot be recovered once it is deleted. All relevant files
                      will be permanently erased as well.
                    </h5>
                    <p>Are you sure?</p>
                  </div>
                ),
                width: 480,
                okText: "Delete application",
                okType: "primary",
                okButtonProps: { danger: true },
                onOk: (close) => {
                  deleteApplication();
                  close();
                },
              });
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default Actions;
