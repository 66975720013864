import React from "react";
import { useSearchParams } from "react-router-dom";
import BeatLoader from "../../components/loaders/BeatLoader";
import { SERVER_BASEURL } from "../../constants";
import useAuth from "../../hooks/useAuth";


export const AuthContext = React.createContext<{ groups?: string[]; logout?: () => void }>({});

const withAuth = (Component: React.FC) => (props: object) => {
  const { authenticated, groups, logout } = useAuth("admin");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  if (authenticated === undefined) {
    return (
      <div className="vh-100">
        <BeatLoader size="50" color="purple" />
      </div>
    );
  }

  if (authenticated === false) {
    return (
      <>
        <button
          style={{ fontSize: "5rem" }}
          onClick={() => window.open(`${SERVER_BASEURL}/admin/auth/google`, "_self")}
        >
          Login
        </button>
        <h3 style={{ color: "red" }}>{searchParams.get("unauthorized") ? "唔畀你入" : null}</h3>
      </>
    );
  }

  return (
    <AuthContext.Provider value={{ groups, logout }}>
      <Component {...props} />
    </AuthContext.Provider>
  );
};

export default withAuth;
