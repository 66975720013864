import { ErrorMessage as FormErrorMessage } from "@hookform/error-message";
import { Icon } from "semantic-ui-react";

const ErrorMessage: React.FC<{ name: string; message?: string }> = (props) => (
  <FormErrorMessage
    render={({ message }) => (
      <div className="mt-1">
        <div style={{ color: "var(--error-color)" }}>
          <Icon name="exclamation triangle" />
          <span>{message}</span>
        </div>
      </div>
    )}
    {...props}
  />
);

export default ErrorMessage;
