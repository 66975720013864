import { UploadFile } from "antd";
import React from "react";
import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon";
import { List } from "semantic-ui-react";
import useInvisibleLinks from "../../hooks/useInvisibleLinks";
import "./FileList.css";

interface FileListProps {
  files: UploadFile[];
}

const FileList: React.FC<FileListProps> = ({ files }) => {
  const { invisibleLinks, openLink } = useInvisibleLinks(files.map(({ url }) => url));
  const listItems = files.map((file, idx) => {
    const nameSplitted = file.name.split(".");
    const extension = nameSplitted[nameSplitted.length - 1] as DefaultExtensionType;
    return (
      <List.Item key={file.name} className="d-flex align-items-center" onClick={openLink(idx)}>
        <div className="fileicon d-inline-block me-3">
          <FileIcon extension={extension} {...defaultStyles[extension]} />
        </div>
        <List.Content className="d-inline-block">
          <List.Header>{file.name}</List.Header>
        </List.Content>
      </List.Item>
    );
  });

  return (
    <>
      {invisibleLinks}
      <List selection verticalAlign="middle">
        {listItems}
      </List>
    </>
  );
};

export default FileList;
