import {
  ErrorBoundaryProps,
  ErrorBoundaryPropsWithComponent,
  ErrorBoundaryPropsWithFallback,
  ErrorBoundaryPropsWithRender,
  ErrorBoundary as ReactErrorBoundary,
} from "react-error-boundary";
import UnknownError from "../../views/errors/UnknownError";

type NoFallback = { fallback?: never; FallbackComponent?: never; fallbackRender?: never };

const hasFallback = (
  props: ErrorBoundaryProps | NoFallback
): props is ErrorBoundaryPropsWithFallback => {
  return props?.fallback !== undefined;
};

const hasFallbackComponent = (
  props: ErrorBoundaryProps | NoFallback
): props is ErrorBoundaryPropsWithComponent => {
  return props?.FallbackComponent !== undefined;
};

const hasFallbackRender = (
  props: ErrorBoundaryProps | NoFallback
): props is ErrorBoundaryPropsWithRender => {
  return props?.fallbackRender !== undefined;
};

const ErrorBoundary: React.FC<
  React.PropsWithRef<React.PropsWithChildren<ErrorBoundaryProps | NoFallback>>
> = ({ children, ...inputProps }) => {
  let props;
  if (
    !hasFallback(inputProps) &&
    !hasFallbackComponent(inputProps) &&
    !hasFallbackRender(inputProps)
  ) {
    props = { fallback: <UnknownError /> };
  } else {
    props = inputProps;
  }
  return <ReactErrorBoundary {...props}>{children}</ReactErrorBoundary>;
};

export default ErrorBoundary;
