import { Input, StrictInputProps } from "semantic-ui-react";
import React from "react";
export interface TextProps extends Omit<StrictInputProps, "onChange"> {
  label?: string;
  editing?: boolean;
  hidden?: boolean;
  error?: boolean;
  value?: string;
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const Text = React.forwardRef<Input, TextProps>((props, ref) => {
  const {
    value,
    label,
    editing = true,
    hidden = false,
    error = false,
    onChange: inputOnChange,
    ...inputProps
  } = props;

  if (hidden) {
    return null;
  }

  let element: JSX.Element;
  if (!editing) {
    element = <span>{value ?? ""}</span>;
  } else {
    element = (
      <Input
        {...{
          ...inputProps,
          ref,
          value,
          error,
          onChange: (event, data) => inputOnChange?.(data.value, event),
        }}
      />
    );
  }

  return (
    <>
      <div className="fw-bold mb-1">{label}</div>
      {element}
    </>
  );
});

export default Text;
