import { javascript } from "@codemirror/lang-javascript";
import { html } from "@codemirror/lang-html";
import { githubLight } from "@uiw/codemirror-theme-github";
import CodeMirror from "@uiw/react-codemirror";
import { Tabs } from "antd";
import { Controller, ControllerProps } from "react-hook-form";
import ErrorMessage from "../../../../components/form/ErrorMessage";
import DatePicker from "../../../../components/inputs/components/DatePicker";
import useReloadPrompt from "../../../../hooks/useReloadPrompt";
import { ConfigVariableList, ExtractStringKeys } from "../types";
import { Text } from "../../../inputs";

interface ConfigEditorProps<SerializedConfig extends {}> {
  configVariableList: ConfigVariableList<ExtractStringKeys<SerializedConfig>>;
  editing: boolean;
  isNew?: boolean;
}

const compileJS = (str: string) => {
  try {
    eval(`const __DUMMY__ = ${str};`);
    return true;
  } catch (e: unknown) {
    return "Compilation Error. Please modify your code to match the syntax requirements.";
  }
};

const ConfigEditer = <
  SerializedConfig extends Record<string, any>,
  InputtedConfig extends Record<ExtractStringKeys<SerializedConfig>, any>,
>({
  configVariableList,
  editing,
  isNew,
}: ConfigEditorProps<SerializedConfig>) => {
  useReloadPrompt(editing || isNew);

  return (
    <Tabs
      tabPosition="left"
      items={configVariableList
        .filter(({ editable, type }) => type !== "metadata" && (!editing || editable || isNew))
        .map(({ key, type, label }) => {
          let render: ControllerProps<InputtedConfig>["render"];
          let validate;
          switch (type) {
            case "year":
              render = ({ field }) => (
                <div style={{ width: "10rem" }}>
                  <DatePicker {...field} editing={editing} picker="year" className="w-100" />
                </div>
              );
              break;

            case "date":
              render = ({ field }) => (
                <div style={{ width: "20rem" }}>
                  <DatePicker {...field} editing={editing} className="w-100" />
                </div>
              );
              break;

            case "text":
              render = ({ field }) => (
                <div style={{ width: "30rem" }}>
                  <Text {...field} editing={editing} className="w-100" />
                </div>
              );
              break;

            case "html":
              render = ({ field }) => (
                <CodeMirror
                  {...field}
                  height="60rem"
                  theme={githubLight}
                  extensions={[html()]}
                  editable={editing}
                />
              );
              break;

            default:
              render = ({ field }) => (
                <CodeMirror
                  {...field}
                  height="60rem"
                  theme={githubLight}
                  extensions={[javascript({ jsx: true, typescript: true })]}
                  editable={editing}
                />
              );
              validate = { compilation: compileJS };
              break;
          }

          return {
            key,
            label,
            children: (
              <>
                <div className="mb-2">
                  <ErrorMessage name={key} />
                </div>
                <Controller
                  name={key}
                  render={render}
                  rules={{
                    required: "This field cannot be empty.",
                    validate,
                  }}
                />
              </>
            ),
          };
        })}
    />
  );
};

export default ConfigEditer;
