import Validator from "../../../../util/validator";
import React, { useContext } from "react";

interface ValidationContextProps {
  validator?: React.MutableRefObject<Validator>;
  allErrorsVisible?: boolean;
}

const ValidationContext = React.createContext<ValidationContextProps>({});

export const ValidationProvider = ValidationContext.Provider;
export const useValidationContext = () => useContext(ValidationContext);
