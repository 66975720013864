import { useEffect, useState } from "react";

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = "";
};

const useUnsafeReloadPrompt = (initialValue?: boolean) => {
  const [unsavedChanges, setUnsavedChanges] = useState(initialValue ?? false);
  useEffect(() => {
    if (unsavedChanges) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [unsavedChanges]);
  return setUnsavedChanges;
};

export default useUnsafeReloadPrompt;
