import { useContext } from "react";
import NavigationBarComponent from "../../components/aahk/NavigationBar";
import { AuthContext } from "./withAuth";

const NavigationBar: React.FC = () => {
  const { groups, logout } = useContext(AuthContext);
  return <NavigationBarComponent menu={[
    { path: "applications", name: "Applications" },
    ...groups?.includes("admin") ? [
      { path: "contributors", name: "Users" },
      {
        name: "Configs",
        submenu: [
          { path: "app-form-configs", name: "Application Form" },
          { path: "process-form-configs", name: "Mentorship Processing" },
          { path: "email-templates", name: "Email Templates" },
        ],
      }] : []
  ]} logout={logout} />;
};

export default NavigationBar;
