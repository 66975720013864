import BathLogo from "./assets/bath-logo.jpg";
import CambridgeLogo from "./assets/cambridge-logo.png";
import DurhamLogo from "./assets/durham-logo.jpeg";
import EdinburghLogo from "./assets/edinburgh-logo.png";
import ImperialLogo from "./assets/imperial-logo.png";
import KCLLogo from "./assets/kcl-logo.png";
import LSELogo from "./assets/lse-logo.png";
import ManchesterLogo from "./assets/manchester-logo.jpg";
import OxfordLogo from "./assets/oxford-logo.png";
import StAndrewsLogo from "./assets/st-andrews-logo.png";
import UCLLogo from "./assets/ucl-logo.png";
import WarwickLogo from "./assets/warwick-logo.png";

export const SERVER_BASEURL: string = process.env.REACT_APP_SERVER_BASEURL as string;
export const FRONTEND_BASEURL: string = process.env.REACT_APP_FRONTEND_BASEURL as string;

// export const ADMIN_SEO_DETAILS: SeoDetails = {
//   title: "Application Management",
//   metaDescription: "Access Abroad Hong Kong - Web app for mananging mentorship applications",
// };

export enum ApplicationStatus {
  WITHDRAWN = "Withdrawn",
  OPEN = "Open",
  RECEIVED = "Received",
  PRE_SCREENED = "Pre-screened",
  APT_TEST_ARRANGED = "Aptitude test arranged",
  APT_TEST_COMPLETED = "Aptitude test completed",
  APT_TEST_MARKED = "Aptitude test marked",
  INTERVIEW_ARRANGED = "Interview arranged",
  INTERVIEW_COMPLETED = "Interview completed",
  PENDING_MENTOR = "Pending for matching",
  MENTOR_MATCHED = "Mentor matched",
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
  ON_HOLD = "On-hold",
  WAITLISTED = "Waitlisted",
  CANCELLED = "Cancelled",
  POOLED = "Pooled",
}

export const UNI_ABBREVIATIONS: Record<string, any> = {
  "University of Cambridge": "Cam",
  "University of Oxford": "Ox",
  "Imperial College London": "ICL",
  "London School of Economics and Political Science": "LSE",
  "University College London": "UCL",
  "University of Warwick": "Warwick",
  "Durham University": "Durham",
  "The University of Edinburgh": "Edi",
  "University of St Andrews": "St Andrews",
  "King's College London": "KCL",
  "University of Bath": "Bath",
  "The University of Manchester": "Manchester",
};

export const UNI_LOGOS: Record<string, any> = {
  "University of Cambridge": CambridgeLogo,
  "University of Oxford": OxfordLogo,
  "Imperial College London": ImperialLogo,
  "London School of Economics and Political Science": LSELogo,
  "University College London": UCLLogo,
  "University of Warwick": WarwickLogo,
  "Durham University": DurhamLogo,
  "The University of Edinburgh": EdinburghLogo,
  "University of St Andrews": StAndrewsLogo,
  "King's College London": KCLLogo,
  "University of Bath": BathLogo,
  "The University of Manchester": ManchesterLogo,
};

export const APPLICATION_DEADLINE: string = "2022-08-21 00:00"; // TODO Remove this
export const MOMENT_DATE_FORMAT_STRING: string = "YYYY-MM-DD HH:mm";
