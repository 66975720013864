import { message } from "antd";
import axios from "axios";
import _ from "lodash";
import prettierPluginHtml from "prettier/plugins/html";
import prettierPluginCSS from "prettier/plugins/postcss";
import prettier from "prettier/standalone";
import * as R from "ramda";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";
import useSWR from "swr";
import Buttons from "../../components/aahk/configManager/components/Buttons";
import ConfigEditer from "../../components/aahk/configManager/components/ConfigEditer";
import Picker from "../../components/aahk/configManager/components/Picker";
import Previewer from "../../components/aahk/configManager/components/Previewer";
import useDraft from "../../components/aahk/configManager/hooks/useDraft";
import { ConfigVariableList } from "../../components/aahk/configManager/types";
import ErrorBoundary from "../../components/wrappers/ErrorBoundary";
import Loading from "../../components/wrappers/Loading";
import { RequestError } from "../../types/Error";
import { fetcher } from "../../util/request";

interface EmailTemplate {
  id: string;
  subject: string;
  html: string;
}

const configVariableList: ConfigVariableList<keyof EmailTemplate> = [
  { type: "metadata", key: "id", label: "ID" },
  { type: "text", key: "subject", label: "Subject", editable: true },
  { type: "html", key: "html", label: "Email", editable: true },
];

const prettify = (str: string) =>
  prettier.format(str, {
    parser: "html",
    plugins: [prettierPluginHtml, prettierPluginCSS],
  });

const prettifyEmailTemplates = async (emailsPromise: Promise<EmailTemplate[]>) => {
  const emails = await emailsPromise;
  const prettifiedHtmls = await Promise.all(emails.map((email) => prettify(email.html)));
  return _.zip(emails, prettifiedHtmls).map(([originalEmail, prettifiedHtml]) => ({
    ...originalEmail,
    html: prettifiedHtml,
  })) as EmailTemplate[];
};

const EmailIdUpdater = ({ setEmailId }: { setEmailId: (id: string) => void }) => {
  const { emailId } = useParams<{ emailId: string }>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setEmailId(emailId!), []);
  return null;
};

const EmailPreviewer: React.FC<{ values?: EmailTemplate }> = ({ values: email }) => {
  if (email === undefined) {
    return null;
  }

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <h4>Subject: Access Abroad Hong Kong -- {email.subject}</h4>
      <div className="d-block">
        <div dangerouslySetInnerHTML={{ __html: email.html }}></div>
      </div>
    </div>
  );
};

const EmailTemplates = () => {
  const {
    data: emails,
    error,
    isLoading,
  } = useSWR<EmailTemplate[], RequestError>(
    "/admin/email-template/list",
    R.compose(prettifyEmailTemplates, fetcher)
  );

  const [emailId, setEmailId] = useState<string>("account-verification");
  const email = _.find(emails, { id: emailId });
  const { formMethods, draft: draftEmail } = useDraft(email);

  const navigate = useNavigate();

  const [{ loading: submitting }, onEdit] = useAsyncFn(async (draft: EmailTemplate) => {
    const { id, ...editedEmail } = draft;
    return axios
      .patch(`/admin/email-template/${id}`, editedEmail)
      .then(() => {
        navigate("");
        message.success("Email template has been edited.");
      })
      .catch(() => message.error("Email template cannot be edited. Please try again."));
  });

  return (
    <Loading loading={false} error={error}>
      <div className="d-flex align-items-center">
        <Picker
          loading={isLoading}
          options={(emails ?? []).map(({ id }) => id)}
          value={emailId}
          onChange={(id) => {
            setEmailId(id);
            navigate("");
          }}
        />
        <Buttons
          {...{
            loading: isLoading,
            id: emailId,
            values: email,
            formMethods,
            onSubmitEdit: formMethods.handleSubmit(onEdit),
            submitting: submitting,
            editConfirmModalContent: <p>This template will be used for all future emails.</p>,
          }}
        />
      </div>
      <FormProvider {...formMethods}>
        <ErrorBoundary>
          <Routes>
            <Route
              path="*"
              element={<ConfigEditer editing={false} {...{ configVariableList }} />}
            />
            <Route
              path=":emailId/*"
              element={
                <>
                  <EmailIdUpdater {...{ setEmailId }} />
                  <Routes>
                    <Route
                      path="preview"
                      element={
                        <Previewer editing={false} values={email} component={EmailPreviewer} />
                      }
                    />
                    <Route path="edit">
                      <Route
                        path=""
                        element={<ConfigEditer editing {...{ configVariableList }} />}
                      />
                      <Route
                        path="preview"
                        element={
                          <Previewer
                            editing
                            values={draftEmail ?? email}
                            component={EmailPreviewer}
                          />
                        }
                      />
                    </Route>
                  </Routes>
                </>
              }
            />
          </Routes>
        </ErrorBoundary>
      </FormProvider>
    </Loading>
  );
};

export default EmailTemplates;
