import { Result } from "antd";

const UnauthorizedError: React.FC = () => (
  <Result
    status="500"
    title="Your request is unauthorized. Your session might have expired."
    subTitle="Try logging out the system and getting back in. If the problem persists, please contact site administrator for assistance."
  />
);

export default UnauthorizedError;
