import { useEffect, useState } from "react";
import { FormConfigOverview } from "../../ConfigManager";

const useCurrentConfigId = (configs?: FormConfigOverview[]) => {
  const [configId, setConfigId] = useState<string>();

  useEffect(() => {
    if (configs !== undefined) {
      setConfigId(configs[configs.length - 1].id);
    }
  }, [configs]);
  return { configId, setConfigId };
};

export default useCurrentConfigId;
