import { Upload as AntUpload } from "antd";
import { UploadProps as AntdUploadProps, UploadChangeParam } from "antd/es/upload";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useEffect, useState } from "react";
import { FileIcon } from "react-file-icon";
import { Button } from "semantic-ui-react";
import FileList from "../../list/FileList";
import SpinLoader from "../../loaders/SpinLoader";

interface UploadProgressEvent {
  percent: number;
}

export interface UploadProps
  extends Omit<AntdUploadProps, "fileList" | "onChange" | "onRemove" | "action"> {
  value?: UploadFile[];
  editing?: boolean;
  hidden?: boolean;
  error?: boolean;
  multiple?: boolean;
  onChange?: (value: UploadFile[], event?: UploadProgressEvent) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onUpload?: (file: UploadFile) => Promise<Partial<UploadFile>>;
  onDelete?: (file: UploadFile) => Promise<void>;
}

const useUploadWindowClosed = ({ loading, onBlur }: { loading: boolean; onBlur?: () => void }) => {
  const [blurred, setBlurred] = useState<boolean>(false);

  useEffect(() => {
    if (blurred && !loading) {
      onBlur?.();
      setBlurred(false);
    }
  }, [blurred, loading, onBlur]);

  function onFileSelected() {
    setTimeout(() => setBlurred(true), 100); // blurred must be set after onChange begins execution, if onChange is fired
    window.removeEventListener("focus", onFileSelected);
  }
  return onFileSelected;
};

const Upload = React.forwardRef<Button, UploadProps>((props, ref) => {
  let {
    value = [],
    editing = true,
    hidden = false,
    error = false,
    multiple = false,
    onChange: inputOnChange,
    onBlur,
    onFocus,
    onUpload,
    onDelete,
    ...uploadProps
  } = props;

  type FileUploadState = [Set<UploadFile>, Promise<UploadFile>[]];
  const [[filesToUpload, uploadPromises], setUploadPromises] = useState<FileUploadState>([
    new Set(),
    [],
  ]);

  const [loading, setLoading] = useState<boolean>(false);
  const onWindowClosed = useUploadWindowClosed({ loading, onBlur });

  // Debouncing for simultaneous uploads of multiple files
  useEffect(() => {
    if (filesToUpload.size > 0 && filesToUpload.size === uploadPromises.length) {
      Promise.all(uploadPromises)
        .then((uploadedFiles) => inputOnChange?.([...value, ...uploadedFiles]))
        .finally(() => setLoading(false));
      setUploadPromises([new Set(), []]);
    }
  }, [value, filesToUpload, uploadPromises, setUploadPromises, inputOnChange]);

  const onChange = async ({
    file: newFile,
    fileList: newFileList,
    event,
  }: UploadChangeParam): Promise<void> => {
    setLoading(true);
    switch (newFile.status) {
      case "uploading":
        setUploadPromises(([filesToUpload, uploadPromises]): FileUploadState => {
          const newUploadedFile = onUpload?.(newFile).then((updatedProperties) => ({
            ...newFile,
            ...updatedProperties,
          })) as Promise<UploadFile>;
          const oldFileNames = new Set(value.map((file) => file.name));
          return [
            new Set(newFileList.filter((file) => !oldFileNames.has(file.name))),
            [...uploadPromises, newUploadedFile],
          ];
        });
        break;

      case "removed":
        onDelete?.(newFile)
          .then(() => inputOnChange?.(newFileList, event))
          .finally(() => setLoading(false));
        break;
    }
  };

  if (hidden) {
    return null;
  }

  if (!editing) {
    return <FileList files={value} />;
  }

  return (
    <div className="d-inline-block">
      <AntUpload
        ref={ref}
        action=""
        listType="picture"
        fileList={value.map((file) => ({ ...file, status: "success" }))}
        multiple={multiple}
        onChange={onChange}
        iconRender={({ name }) => {
          const nameSplitted = name.split(".");
          return <FileIcon extension={nameSplitted[nameSplitted.length - 1]} />;
        }}
        {...uploadProps}
      >
        <div className="d-flex align-items-center">
          <Button
            icon="upload"
            content="Upload"
            color={error ? undefined : "orange"}
            negative={error}
            inverted
            className="small"
            onClick={() => {
              onFocus?.();
              window.addEventListener("focus", onWindowClosed);
            }}
          />
          <div className="d-inline-block ms-2">
            <SpinLoader size="40" color="orange" loading={loading} />
          </div>
        </div>
      </AntUpload>
    </div>
  );
});

export default Upload;
