import React from "react";
import { Route, Routes } from "react-router-dom";
import BeatLoader from "../../components/loaders/BeatLoader";
import useAuth from "../../hooks/useAuth";
import AuthPage from "./AuthPage";
import { AuthPageMode } from "./AuthPage/states";
import "./index.css";


export const AuthContext = React.createContext<{ logout?: () => void }>({});

const withAuth = (Component: React.FC) => (props: object) => {
  const { authenticated, renewSession, logout } = useAuth("apply");

  if (authenticated === undefined) {
    return (
      <div className="vh-100">
        <BeatLoader size="50" color="purple" />
      </div>
    );
  }

  if (authenticated === false) {
    return (
      <Routes>
        <Route
          path="verify/:verificationToken"
          element={<AuthPage mode={AuthPageMode.VERIFY_EMAIL} />}
        />
        <Route
          path="reset-password/:resetPasswordToken"
          element={<AuthPage mode={AuthPageMode.RESET_PASSWORD} />}
        />
        <Route
          path="/*"
          element={<AuthPage mode={AuthPageMode.LOGIN} renewSession={renewSession} />}
        />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider value={{ logout }}>
      <Component {...props} />
    </AuthContext.Provider>
  );
};

export default withAuth;
