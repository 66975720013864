import ReactBeatLoader from "react-spinners/BeatLoader";
import { LoaderSizeMarginProps } from "react-spinners/helpers/props";

const BeatLoader: React.FC<LoaderSizeMarginProps> = (props) => {
  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
      <ReactBeatLoader size="50" color="purple" {...props} />
    </div>
  );
};

export default BeatLoader;
