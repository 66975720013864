import { Result } from "antd";

const UnknownError: React.FC = () => (
  <Result
    status="500"
    title="Something went wrong..."
    subTitle="Try logging out the system and getting back in. If the problem persists, please contact site administrator for assistance."
  />
);

export default UnknownError;
