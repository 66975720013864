import React, { useEffect, useState } from "react"
import useSWR from 'swr';
import { fetcher } from '../../../../util/request'
import { Selection, Checkbox } from '../../../../components/inputs'
import Loading from "../../../../components/wrappers/Loading";
import { useAsyncFn } from "react-use";
import axios from "axios";
import { FormProvider } from "antd/es/form/context";
import { Controller, useFormContext } from "react-hook-form";


const Field = ({ editing, control, availableUsers, availableGroups }) => {
  return <>
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => {
        return <div className="my-4">
          <div className="fw-bold mb-2">
            Permitted Users
          </div>
          <div className="ws-pre-line">
            <Selection editing={editing} mode="multiple" onChange={onChange} className="w-100" value={value} options={availableUsers?.map(user => {
              return {
                label: user.name,
                value: user.id
              }
            })} />
          </div>
        </div>
      }}
      name="users" />

    <Controller
      control={control}
      render={({ field: { onChange, value } }) => {
        return <div className="my-4">
          <div className="fw-bold mb-2">
            Permitted Groups
          </div>

          <div className="ws-pre-line">
            <Checkbox editing={editing} className="w-100" onChange={onChange} value={value} options={availableGroups} />
          </div>
        </div>
      }}
      name="groups" />
  </>
}

const Permissions = ({ editing, applicationId, formMethods }) => {

  const {
    data: availableUsers,
    isLoading: isLoadingUsers
  } = useSWR<{ id: string, name: string }[]>(
    `/admin/contributor/list`,
    fetcher
  );

  const {
    data: availableGroups,
    isLoading: isLoadingGroups
  } = useSWR<string[]>('/admin/permission-group/list', fetcher)

  return (
    <FormProvider {...formMethods}>
      <Loading loading={isLoadingUsers || isLoadingGroups}>
        <Field editing={editing} control={formMethods.control} availableUsers={availableUsers} availableGroups={availableGroups} />
      </Loading>
    </FormProvider>
  )

}

export default Permissions 