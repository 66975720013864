import { List, message } from "antd";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { Button, Card, Icon } from "semantic-ui-react";
import useSWR from "swr";
import StatusLabel from "../../../components/aahk/StatusLabel";
import { ApplicationStatus } from "../../../constants";
import ErrorBoundary from "../../../components/wrappers/ErrorBoundary";
import Loading from "../../../components/wrappers/Loading";
import Application from "../../../types/apply/Application";
import { fetcher } from "../../../util/request";

type ApplicationOverview = Omit<Application, "savedForm"> & { id: string };

const ApplicationList = () => {
  const { data: applications, isLoading } = useSWR<ApplicationOverview[]>(
    "/apply/application/list",
    fetcher
  );

  const navigate = useNavigate();

  return (
    <List
      itemLayout="horizontal"
      dataSource={applications}
      loading={isLoading}
      renderItem={(application, idx) => {
        const { id, cycle, submitted, creationTimestamp, submissionTimestamp } = application;

        let description;
        if (submitted) {
          const submissionDate = new Date(submissionTimestamp as string).toLocaleString();
          description = `Submitted at ${submissionDate}`;
        } else {
          const creationDate = new Date(creationTimestamp).toLocaleString();
          description = `Created at ${creationDate}`;
        }

        const applicationLink = submitted
          ? `/apply/application/${id}`
          : `/apply/application/${id}/edit`;

        return (
          <Card onClick={() => navigate(applicationLink)}>
            <Card.Content className="pb-0">
              <div className="d-flex justify-content-between text-black">
                <h3>{cycle} Admission</h3>
                <div>
                  <StatusLabel status={submitted ? ApplicationStatus.RECEIVED : ApplicationStatus.OPEN} ribbon="right" />
                </div>
              </div>
              {/* <Card.Content description="" /> */}
            </Card.Content>
            <Card.Content extra>{description}</Card.Content>
          </Card>
        );
      }}
    />
  );
};

const Applications: React.FC = () => {
  const {
    data: applications,
    error,
    isLoading,
    mutate,
  } = useSWR<ApplicationOverview[], AxiosError>("/apply/application/list", fetcher);

  const [{ loading: creatingApplication }, createApplication] = useAsyncFn(() =>
    axios
      .post("/apply/application/create")
      .then((response) => mutate())
      .catch((error) => message.error("Cannot create application. Please try again."))
  );

  if (error) {
    message.error("Cannot load applications.");
    return null;
  }

  // TODO This should be done with a system variable instead
  const currentYear = new Date().getFullYear();
  const applicationCreated = applications?.some(({ cycle }) => cycle === currentYear);

  return (
    <Loading loading={isLoading}>
      <div className="d-flex align-items-center mb-4">
        <div className="d-inline-block me-4">
          <h2>Your applications</h2>
        </div>
        {!applicationCreated && (
          <Button
            color="facebook"
            size="small"
            icon
            labelPosition="right"
            className="mt-3 mt-sm-0"
            loading={creatingApplication}
            onClick={createApplication}
          >
            Create application <Icon name="plus" />
          </Button>
        )}
      </div>
      <ErrorBoundary>
        <ApplicationList />
      </ErrorBoundary>
    </Loading>
  );
};

export default Applications;
