import "ag-grid-enterprise";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import { SERVER_BASEURL } from "./constants";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Axios setup
if (process.env.NODE_ENV === "development") {
  axios.interceptors.request.use(
    (req) => {
      console.log(req);
      return req;
    },
    (err) => {
      console.log(err);
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      console.log(response);
      return response;
    },
    (err) => {
      console.log(err);
      console.log("Error message: " + err.message);
      return Promise.reject(err);
    }
  );
}
axios.defaults.baseURL = SERVER_BASEURL;
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
// React.StrictMode is commented out because it mounts the components twice during development
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
