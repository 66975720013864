import { A, MapOver, Type } from "free-types";
import { PickerMode } from "rc-picker/lib/interface";
import { SelectionProps } from "../../components/inputs/components/Selection";
import { LabelledOption } from "../../components/inputs/util";
import { TimePickerProps } from "antd";

export enum FieldType {
  TEXT = "text",
  TEXTAREA = "textarea",
  RADIO = "radio",
  CHECKBOX = "checkbox",
  SELECTION = "selection",
  TABLE = "table",
  FILES = "files",
  DATE = "date",
}

export type BasicFields = FieldType.TEXT | FieldType.TEXTAREA | FieldType.FILES;
export type ChoicesFields = FieldType.RADIO | FieldType.CHECKBOX | FieldType.SELECTION;
export type SingleFields = BasicFields | ChoicesFields | FieldType.DATE;

interface AbstractFieldConfig<T extends FieldType> {
  type: T;
  label: string;
  shortLabel?: string;
  description?: string;
  validationRules?: string | (string | object)[];
}

// Basic fields
export interface BasicConfig<T extends BasicFields> extends AbstractFieldConfig<T> {}
export type BasicConfigs = BasicConfig<BasicFields>;

// Option fields
export interface OptionConfig<T extends ChoicesFields> extends AbstractFieldConfig<T> {
  options: string[] | LabelledOption[];
  customOption?: boolean;
  sort?: boolean;
}
export interface SelectionConfig extends OptionConfig<FieldType.SELECTION> {
  mode?: SelectionProps["mode"];
}
export type ChoicesConfig<T extends ChoicesFields> = T extends FieldType.SELECTION
  ? SelectionConfig
  : OptionConfig<T>;
export type ChoicesConfigs = ChoicesConfig<ChoicesFields>;

// Time Field
export interface TimeConfig extends AbstractFieldConfig<FieldType.DATE> {
  picker?: PickerMode;
  showTime?: TimePickerProps;
}

// Single fields (Non-composite fields)
export type SingleConfig<T extends SingleFields> = T extends BasicFields
  ? BasicConfig<T>
  : T extends ChoicesFields
  ? ChoicesConfig<T>
  : T extends FieldType.DATE
  ? TimeConfig
  : BasicConfig<Extract<T, BasicFields>> | OptionConfig<Extract<T, ChoicesFields>> | TimeConfig;
export type SingleConfigs = SingleConfig<SingleFields>;

// Table
type RowConfig<T extends SingleFields> = {
  [Field in T]: Omit<SingleConfig<Field>, "shortLabel" | "description"> & {
    key: string;
    width?: number;
  };
}[T];
interface $RowConfig extends Type<[SingleFields]> {
  type: RowConfig<A<this>>;
}
// type Map<T extends InputProps[], $U extends Type<[InputProps]>> = {
//   [K in keyof T]: K extends number ? apply<$U, [T[K]]> : T[K];
// };
export interface TableConfig<T extends SingleFields[]>
  extends AbstractFieldConfig<FieldType.TABLE> {
  row: MapOver<T, $RowConfig>;
  buttonText: string;
}
export type TableConfigs = TableConfig<SingleFields[]>;

export type InputConfigs = BasicConfigs | ChoicesConfigs | TimeConfig | TableConfigs;
export type InputConfig<T extends FieldType> = T extends SingleFields
  ? SingleConfig<T>
  : TableConfigs;

type QuestionConfig = Record<string, InputConfigs>;
export default QuestionConfig;
