import { UploadFile } from "antd";
import { useState } from "react";
import SimpleReactValidator from "simple-react-validator";

// TODO Checkbox, Upload, Table: Validate array values as well, on top of simple strings

interface ValidatedInputProps<HTMLElementType extends HTMLElement> {
  value?: string | string[] | UploadFile[];
  hidden?: boolean;
  error?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLElementType>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElementType>) => void;
}

export interface ValidationProps {
  validation?: {
    id: string;
    validator: React.MutableRefObject<SimpleReactValidator>;
    rules: string | (string | object)[];
    options?: object; // type: SimpleReactValidator.IOptions
    mustShowError?: boolean;
  };
}

export default function validate<
  HTMLElementType extends HTMLElement,
  ComponentProps extends ValidatedInputProps<HTMLElementType>
>(Component: React.FC<ComponentProps>): React.FC<ComponentProps & ValidationProps> {
  return (props) => {
    const {
      value,
      hidden,
      error: inputError,
      onBlur: inputOnBlur,
      onFocus: inputOnFocus,
      validation,
      ...componentProps
    } = props;

    // const [touched, setTouched] = useState(false);
    const [onceBlurred, setOnceBlurred] = useState(false);
    const [focused, setFocused] = useState(false);

    if (!validation || !validate) {
      // Type-checker fails to recognise props has the same type as ComponentProps
      const combinedProps = { ...componentProps, value } as unknown as ComponentProps;
      return <Component {...combinedProps} />;
    }

    const { id, validator, rules, options = {}, mustShowError = false } = validation;

    // const errorVisible = (touched && !focused) || showError;
    // const errorMessageVisible = touched || showError;
    const errorVisible = (onceBlurred && !focused) || mustShowError;
    const errorMessageVisible = onceBlurred || mustShowError;

    if (errorMessageVisible) {
      validator.current.showMessageFor(id);
    }

    let error, errorMessage;
    if (rules) {
      errorMessage = validator.current.message(id, value, rules, options);
      error = !validator.current.fieldValid(id) && errorVisible;
    }

    if (hidden) {
      // This line is for clearing the hidden fields in the validator
      errorMessage = validator.current.message(id, "", "string");
    }

    const combinedProps = {
      value,
      hidden,
      error: error || inputError,
      onBlur: (event: React.FocusEvent<HTMLElementType>) => {
        setOnceBlurred(true);
        setFocused(false);
        inputOnBlur?.(event);
      },
      onFocus: (event: React.FocusEvent<HTMLElementType>) => {
        setFocused(true);
        inputOnFocus?.(event);
      },
      ...componentProps,
    } as unknown as ComponentProps;

    if (id === "reportCards") {
      console.log(onceBlurred, focused);
    }

    return (
      <>
        <Component {...combinedProps} />
        <div className="mt-1">{errorMessage}</div>
      </>
    );
  };
}
