import _ from "lodash";
import { CallbackEntry } from "../../../types/formBodyConfig/CallbackConfig";
import * as R from "ramda";

class CallbacksInvoker {
  protected newFormState: object;

  constructor(
    protected formState: object,
    protected callbacks: CallbackEntry[]
  ) {
    this.newFormState = _.cloneDeep(formState);
  }

  protected getValues(keys: string[]) {
    return keys.map((key) => _.get(this.formState, key));
  }

  protected setValue(key: string, value: unknown) {
    _.set(this.newFormState, key, value);
  }

  public invoke() {
    for (let { inputs: inputKeys, outputs: outputKeys, callback } of this.callbacks) {
      if (!Array.isArray(inputKeys)) inputKeys = [inputKeys];

      const inputValues = this.getValues(inputKeys);
      let outputValues = callback(...inputValues);

      if (!Array.isArray(outputKeys)) {
        outputKeys = [outputKeys];
        outputValues = [outputValues];
      }

      const originalValues = this.getValues(outputKeys);
      _.zip(outputKeys, outputValues, originalValues)
        .filter(([outputKey, outputValue, originalValue]) => outputValue !== undefined)
        .forEach(([outputKey, outputValue, originalValue]) => {
          if (!R.equals(outputValue, originalValue)) {
            this.setValue(outputKey!, outputValue);
          }
        });
    }
    if (R.equals(this.formState, this.newFormState)) {
      return this.formState;
    } else {
      return this.newFormState;
    }
  }
}

export default CallbacksInvoker;
