import { Col, Container, Row } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "semantic-ui-react";
import { BasicConfigs, SingleFields, TableConfig } from "../../../../types/formBodyConfig/QuestionConfig";
import FieldElement from "./FieldElement";
import { useValidationContext } from "./ValidationContext";
import ErrorMessage from "../../../../components/form/ErrorMessage";

export interface TableProps<T extends SingleFields[]>
  extends Omit<TableConfig<T>, Exclude<keyof BasicConfigs, "validationRules">> {
  fieldKey: string;
  name: string;
  editing?: boolean;
}

const Table = <T extends SingleFields[]>(props: TableProps<T>) => {
  const { fieldKey, name, row, editing = true, validationRules, buttonText } = props;
  const { validator } = useValidationContext();
  const { fields, append, remove } = useFieldArray({
    name,
    rules: validator?.current.getOptions(fieldKey, validationRules),
  });

  const { getFieldState } = useFormContext();
  const { error } = getFieldState(name);

  const header = (
    <Row key="header">
      {row.map(({ label, width }) => (
        <Col key={label} xs={width} className="font-italic">
          {label}
        </Col>
      ))}
    </Row>
  );

  const rows = fields.map(({ id }, rowIdx) => (
    <Row className="my-1" key={id}>
      {row.map(({ key, label: _label, width, ...componentProps }) => (
        <Col key={key} xs={width}>
          <FieldElement
            {...componentProps}
            fieldKey={`${fieldKey}.${rowIdx}.${key}`}
            name={`${name}.${rowIdx}.${key}`}
            editing={editing}
            className="w-100"
          />
        </Col>
      ))}
      {editing && (
        <Col className="align-self-center">
          <Button
            icon="minus"
            color="red"
            size="mini"
            inverted
            circular
            onClick={() => remove(rowIdx)}
          />
        </Col>
      )}
    </Row>
  ));

  return (
    <div>
      <Container fluid className="px-0">
        {header}
        {rows}
      </Container>
      {editing && (
        <Button
          icon="add"
          content={buttonText ?? "Add row"}
          color={error ? undefined : "brown"}
          negative={error !== undefined}
          size="small"
          inverted
          compact
          className="mt-2"
          onClick={() => append(row.reduce((obj, { key }) => ({ ...obj, [key]: null }), {}))}
        />
      )}
      <ErrorMessage name={name} message={error?.root?.message} />
    </div>
  );
};

export default Table;
