import { useLocation } from "react-router-dom";
import { Selection } from "../../../inputs";
import BeatLoader from "../../../loaders/BeatLoader";
import { SelectionProps } from "../../../inputs/components/Selection";

const Picker: React.FC<SelectionProps<string>> = (props) => {
  const { pathname } = useLocation();
  
  return (
    <Selection
      notFoundContent={props?.loading ? <BeatLoader size="20" /> : null}
      allowClear={false}
      disabled={pathname.includes("create") || pathname.includes("edit")}
      style={{ width: "20rem" }}
      className="m-3"
      {...props}
    />
  );
};

export default Picker;
