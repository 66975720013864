import { Result } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";

export interface Error500Props {
  to?: string;
  buttonText?: string;
}

const Error500: React.FC<Error500Props> = ({ to, buttonText }) => {
  const navigate = useNavigate();
  return (
    <Result
      status="500"
      title="500 Internal Server Error"
      subTitle="Sorry, something went wrong."
      extra={
        buttonText ? (
          <Link to={`/${to}`}>
            <Button primary>{buttonText}</Button>
          </Link>
        ) : (
          <Button primary onClick={() => navigate(0)}>
            Click to Refresh
          </Button>
        )
      }
    />
  );
};

export default Error500;
