import { useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import LinkButton from "./LinkButton";
import { AuthPageMode } from "../states";

const Header: React.FC<{
  mode: AuthPageMode;
  switchModeHandler: (newMode: AuthPageMode) => void;
}> = ({ mode, switchModeHandler }) => {
  const navigate = useNavigate();

  const SwitchToLogin: React.FC<{ text: string; newPage?: boolean }> = ({
    text,
    newPage = false,
  }) => (
    <LinkButton
      onClick={() => {
        if (newPage) navigate("/apply", { replace: true });
        switchModeHandler(AuthPageMode.LOGIN);
      }}
    >
      {text}
    </LinkButton>
  );

  let content;
  let modeSwitcher;
  switch (mode) {
    case AuthPageMode.LOGIN:
      content = "Applicant Login";
      modeSwitcher = (
        <>
          <span className="text-secondary mx-3 d-none d-sm-inline">Don't have an account?</span>
          <Button content="Sign Up" onClick={() => switchModeHandler(AuthPageMode.SIGNUP)} />
        </>
      );
      break;

    case AuthPageMode.SIGNUP:
      content = "Register Account";
      modeSwitcher = <SwitchToLogin text="Already have an account?" />;
      break;

    case AuthPageMode.SIGNUP_ACCEPTED:
      content = "Register Account";
      modeSwitcher = <SwitchToLogin text="Go to Login Page" />;
      break;

    case AuthPageMode.VERIFY_EMAIL:
      content = "Verify Email";
      break;

    case AuthPageMode.VERIFICATION_SUCCESS:
    case AuthPageMode.VERIFICATION_ERROR:
      content = "Verify Email";
      modeSwitcher = <SwitchToLogin text="Go to Login Page" newPage />;
      break;

    case AuthPageMode.FORGOT_PASSWORD:
      content = "Forgot Password";
      modeSwitcher = <SwitchToLogin text="Remember your password?" />;
      break;

    case AuthPageMode.PASSWORD_RESET_INITIATED:
      content = "Forgot Password";
      modeSwitcher = <SwitchToLogin text="Go to Login Page" />;
      break;

    case AuthPageMode.RESET_PASSWORD:
    case AuthPageMode.RESET_PASSWORD_ACCEPTED:
      content = "Reset Password";
      modeSwitcher = <SwitchToLogin text="Go to Login Page" newPage />;
      break;

    default:
      const _exhaustiveCheck: never = mode;
      throw new Error(`Unhandled mode: ${mode}`);
  }

  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <div>
        <h2>{content}</h2>
      </div>
      <div>{modeSwitcher}</div>
    </div>
  );
};

export default Header;
