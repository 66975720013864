import SimpleReactValidator from "simple-react-validator";
import { Updater } from "use-immer";
import { AuthPageMode } from "../states";
import { ConfirmPassword, Credentials, Email, Password } from "./Inputs";
import { Message } from "semantic-ui-react";

const Text: React.FC<{ children: string }> = ({ children }) => (
  <p className="authpage-text">{children}</p>
);

const Body: React.FC<{
  mode: AuthPageMode;
  credentials: Credentials;
  setCredentials: Updater<Credentials>;
  validator: React.MutableRefObject<SimpleReactValidator>;
  allErrorsShown: boolean;
}> = ({ mode, credentials, setCredentials, validator, allErrorsShown }) => {
  const inputProps = {
    credentials,
    setCredentials,
    validator,
    mustShowError: allErrorsShown,
    validate: [AuthPageMode.SIGNUP, AuthPageMode.RESET_PASSWORD].includes(mode),
  };

  const emailInput = <Email {...inputProps} />;
  const passwordInput = <Password {...inputProps} />;
  const confirmPasswordInput = <ConfirmPassword {...inputProps} />;

  switch (mode) {
    case AuthPageMode.LOGIN:
      return <>{[emailInput, passwordInput]}</>;

    case AuthPageMode.SIGNUP:
      return (
        <div>
          <Message>
            <Message.Header>Please sign up with your personal email.</Message.Header>
            <p className="mt-1">
              Your personal email is preferred (Gmail ideally) instead of your school email as we
              will be sharing documents to mentee's emails via Google.
            </p>
          </Message>
          {emailInput}
          {passwordInput}
          {confirmPasswordInput}
        </div>
      );

    case AuthPageMode.SIGNUP_ACCEPTED:
      return (
        <Text>
          An email will be sent to your registered mailbox. Please confirm your account with the
          email. Don't forget to check your spam or junk mailbox.
        </Text>
      );

    case AuthPageMode.VERIFY_EMAIL:
      return <Text>Verifying...</Text>;

    case AuthPageMode.VERIFICATION_SUCCESS:
      return <Text>You may now use your credentials to login.</Text>;

    case AuthPageMode.VERIFICATION_ERROR:
      return (
        <Text>
          Please check your token or use "Forgot Password" to verify your email address again.
        </Text>
      );

    case AuthPageMode.FORGOT_PASSWORD:
      return emailInput;

    case AuthPageMode.PASSWORD_RESET_INITIATED:
      return (
        <Text>
          A password reset email will be sent to the email address if there is an account associated
          with the email address. Don't forget to check your spam or junk mailbox.
        </Text>
      );

    case AuthPageMode.RESET_PASSWORD:
      return <>{[passwordInput, confirmPasswordInput]}</>;

    case AuthPageMode.RESET_PASSWORD_ACCEPTED:
      return <Text>You may now login to your account with the new password.</Text>;

    default:
      const _exhaustiveCheck: never = mode;
      throw new Error(`Unhandled mode: ${mode}`);
  }
};

export default Body;
