import { Label, LabelProps, SemanticCOLORS } from "semantic-ui-react";
import { ApplicationStatus } from "../../constants";

const statusConfig: Record<ApplicationStatus, { label: string; color?: SemanticCOLORS }> = {
  [ApplicationStatus.WITHDRAWN]: { label: "Withdrawn", color: "grey" },
  [ApplicationStatus.OPEN]: { label: "Open" },
  [ApplicationStatus.RECEIVED]: { label: "Submitted", color: "purple" },
  [ApplicationStatus.PRE_SCREENED]: { label: "Pre-screened", color: "teal" },
  [ApplicationStatus.APT_TEST_ARRANGED]: { label: "Apt Test Arranged", color: "yellow" },
  [ApplicationStatus.APT_TEST_COMPLETED]: { label: "Apt Test Completed", color: "yellow" },
  [ApplicationStatus.APT_TEST_MARKED]: { label: "Apt Test Marked", color: "yellow" },
  [ApplicationStatus.INTERVIEW_ARRANGED]: { label: "Interview Arranged", color: "orange" },
  [ApplicationStatus.INTERVIEW_COMPLETED]: { label: "Interview Completed", color: "orange" },
  [ApplicationStatus.PENDING_MENTOR]: { label: "Pending Mentor", color: "brown" },
  [ApplicationStatus.MENTOR_MATCHED]: { label: "Mentor Matched", color: "brown" },
  [ApplicationStatus.ACCEPTED]: { label: "Accepted", color: "green" },
  [ApplicationStatus.REJECTED]: { label: "Rejected", color: "grey" },
  [ApplicationStatus.ON_HOLD]: { label: "On-hold" },
  [ApplicationStatus.WAITLISTED]: { label: "Waitlisted" },
  [ApplicationStatus.CANCELLED]: { label: "Cancelled", color: "grey" },
  [ApplicationStatus.POOLED]: { label: "Pooled" },
};

interface StatusLabelProps extends LabelProps {
  status: ApplicationStatus;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status, ...labelProps }) => {
  if (!status) {
    return null;
  }

  const { label, color } = statusConfig[status];
  return <Label color={color} content={label} {...labelProps} />;
};

export default StatusLabel;
