import { Modal, message } from "antd";
import { UseFormReturn } from "react-hook-form";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";

interface ButtonsProps<FormValues extends {}> {
  loading: boolean;
  id?: string;
  values?: FormValues;
  formMethods: UseFormReturn<FormValues>;
  onSubmitEdit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  onSubmitCreate?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  submitting: boolean;
  allowUnsafeEdits?: boolean;
  toggleUnsafeEdits?: (value?: boolean) => void;
  editConfirmModalContent?: string | React.ReactNode;
}

type EditButtonsProps<FormValues extends {}> = Pick<
  ButtonsProps<FormValues>,
  "values" | "formMethods" | "allowUnsafeEdits" | "toggleUnsafeEdits"
> & {
  isNew?: boolean;
};

const EditButtons = <FormValues extends {}>({
  values,
  formMethods,
  allowUnsafeEdits,
  toggleUnsafeEdits,
  isNew,
}: EditButtonsProps<FormValues>) => {
  const navigate = useNavigate();

  return (
    <div className="w-100 d-flex justify-content-between">
      <div>
        <Button
          icon="close"
          content="Cancel"
          size="small"
          onClick={() => {
            formMethods.reset(values);
            toggleUnsafeEdits?.(false);
            navigate(isNew ? ".." : "../..");
          }}
          className="mx-2"
        />
        {toggleUnsafeEdits && !allowUnsafeEdits ? (
          <Button
            negative
            content="Allow unsafe edits"
            size="small"
            onClick={() =>
              Modal.confirm({
                title: "危險動作: 後果自負!",
                content: (
                  <div>
                    <p>This may permanently corrupt previous applications.</p>
                    <h5>
                      Do not proceed unless you are sure you understand the consequences of these
                      edits.
                    </h5>
                  </div>
                ),
                okText: "Proceed",
                okType: "primary",
                okButtonProps: { danger: true },
                onOk: (close) => {
                  toggleUnsafeEdits();
                  close();
                },
                width: 600,
              })
            }
          />
        ) : null}
      </div>
      <Button
        icon="eye"
        content={`Preview ${isNew ? "new" : "edited"} form`}
        size="small"
        color="purple"
        onClick={async () => {
          const isValid = await formMethods.trigger();
          if (isValid) {
            navigate("preview");
          } else {
            message.error("Some inputs are invalid.");
          }
        }}
      />
    </div>
  );
};

interface PreviewButtonsProps<FormValues extends {}> {
  onSubmit: ButtonsProps<FormValues>["onSubmitEdit" | "onSubmitCreate"];
  submitting: ButtonsProps<FormValues>["submitting"];
  toggleUnsafeEdits: ButtonsProps<FormValues>["toggleUnsafeEdits"];
  editConfirmModalContent: ButtonsProps<FormValues>["editConfirmModalContent"];
  isNew?: boolean;
}

const PreviewButtons = <FormValues extends {}>({
  onSubmit,
  submitting,
  toggleUnsafeEdits,
  editConfirmModalContent,
  isNew,
}: PreviewButtonsProps<FormValues>) => {
  const navigate = useNavigate();

  return (
    <div className="w-100 d-flex justify-content-between">
      <Button
        icon="arrow left"
        content="Go back"
        size="small"
        onClick={() => navigate("..")}
        className="mx-2"
      />
      <Button
        icon="check"
        content={"Confirm" + (isNew ? "" : " edits")}
        size="small"
        color="green"
        onClick={() =>
          Modal.confirm({
            title: "Are you sure?",
            content: isNew ? (
              <div>
                <p>Once this version is created, it is here to stay. It is unerasable.</p>
                <h5>This version will be immediately rolled out to all future applications.</h5>
              </div>
            ) : (
              editConfirmModalContent ?? (
                <p>
                  Edits will be immediately rolled out to all applications attached to this config.
                </p>
              )
            ),
            okText: "Confirm",
            okType: "primary",
            onOk: (close) => {
              onSubmit?.();
              toggleUnsafeEdits?.(false);
              close();
            },
            width: 600,
          })
        }
        loading={submitting}
      />
    </div>
  );
};

const Buttons = <FormValues extends {}>({
  loading,
  id,
  values,
  formMethods,
  onSubmitEdit,
  onSubmitCreate,
  submitting,
  allowUnsafeEdits,
  toggleUnsafeEdits,
  editConfirmModalContent,
}: ButtonsProps<FormValues>) => {
  const navigate = useNavigate();

  if (loading) {
    return null;
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <div className="w-100 d-flex justify-content-between">
            <div>
              <Button
                icon="eye"
                content="View"
                size="small"
                color="purple"
                onClick={() => navigate(`${id}/preview`)}
                className="me-2"
              />
              <Button
                icon="pencil"
                content="Edit"
                size="small"
                onClick={() => navigate(`${id}/edit`)}
              />
            </div>
            {onSubmitCreate ? (
              <Button
                primary
                icon="plus"
                content="Create new form"
                size="small"
                onClick={() => navigate("create")}
              />
            ) : null}
          </div>
        }
      />
      <Route path=":id">
        <Route
          path="preview"
          element={
            <div className="w-100 d-flex justify-content-between">
              <Button
                icon="arrow left"
                content="Go back"
                size="small"
                onClick={() => navigate("")}
                className="mx-2"
              />
            </div>
          }
        />
        <Route path="edit">
          <Route
            path=""
            element={
              <EditButtons {...{ values, formMethods, allowUnsafeEdits, toggleUnsafeEdits }} />
            }
          />
          <Route
            path="preview"
            element={
              <PreviewButtons
                {...{
                  onSubmit: onSubmitEdit,
                  submitting,
                  toggleUnsafeEdits,
                  editConfirmModalContent,
                }}
              />
            }
          />
        </Route>
      </Route>
      {onSubmitCreate ? (
        <Route path="create">
          <Route path="" element={<EditButtons isNew {...{ values, formMethods }} />} />
          <Route
            path="preview"
            element={
              <PreviewButtons
                isNew
                {...{
                  onSubmit: onSubmitCreate,
                  submitting,
                  toggleUnsafeEdits,
                  editConfirmModalContent,
                }}
              />
            }
          />
        </Route>
      ) : null}
    </Routes>
  );
};

export default Buttons;
