import { Button } from "semantic-ui-react";
import { Action, AuthPageMode } from "../states";
import LinkButton from "./LinkButton";

const Footer = ({ loading, buttonRef, mode, authHandler, switchModeHandler }) => {
  let buttonProps = {};
  let switchModeComponent = null;
  switch (mode) {
    case AuthPageMode.LOGIN:
      buttonProps = {
        ...buttonProps,
        content: "Login",
        onClick: () => authHandler(Action.LOGIN),
      };
      switchModeComponent = (
        <LinkButton onClick={() => switchModeHandler(AuthPageMode.FORGOT_PASSWORD)}>
          Forgot password?
        </LinkButton>
      );
      break;

    case AuthPageMode.SIGNUP:
      buttonProps = {
        ...buttonProps,
        content: "Sign Up",
        onClick: () => authHandler(Action.SIGNUP),
      };
      break;

    case AuthPageMode.FORGOT_PASSWORD:
      buttonProps = {
        ...buttonProps,
        content: "Initiate Password Reset",
        onClick: () => authHandler(Action.INITIATE_PASSWORD_RESET),
      };
      break;

    case AuthPageMode.RESET_PASSWORD:
      buttonProps = {
        ...buttonProps,
        content: "Reset Password",
        onClick: () => authHandler(Action.RESET_PASSWORD),
      };
      break;

    default:
      return null;
  }
  return (
    <>
      <Button primary loading={loading} ref={buttonRef} {...buttonProps} />
      {switchModeComponent}
    </>
  );
};

export default Footer;
