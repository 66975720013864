import React from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import { Form } from "semantic-ui-react";

export interface TextareaProps extends Omit<TextareaAutosizeProps, "onChange"> {
  value?: string;
  editing?: boolean;
  hidden?: boolean;
  error?: boolean;
  autosize?: boolean;
  onChange?: (value: string, event: React.FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  let {
    editing = true,
    hidden = false,
    error = false,
    autosize = true,
    value,
    onChange: inputOnChange,
    ...textareaProps
  } = props;

  if (hidden) {
    return null;
  }

  if (!editing) {
    return <span>{value ?? ""}</span>;
  }

  const augmentedProps = {
    ...textareaProps,
    ref,
    value,
    onChange: (event: React.FormEvent<HTMLTextAreaElement>) =>
      inputOnChange?.(event.currentTarget.value, event),
  };

  return (
    <Form>
      <Form.Field error={error}>
        {autosize ? (
          <TextareaAutosize minRows={3} maxRows={15} {...augmentedProps} />
        ) : (
          <textarea {...augmentedProps} />
        )}
      </Form.Field>
    </Form>
  );
});

export default Textarea;
