import { Select, SelectProps } from "antd";
import { DefaultOptionType, RefSelectProps } from "antd/es/select";
import { Ref, useState } from "react";
import {
  Options,
  compareOptions,
  containsOnlySpaces,
  defaultLabelRender,
  getLabels,
  standardiseOptions,
} from "../util";
import "./Selection.css";
import React from "react";

type ValueType = string | string[];

export interface SelectionProps<V extends ValueType = ValueType>
  extends Omit<SelectProps<V>, "options" | "onChange"> {
  value?: V;
  options: Options;
  customOption?: boolean;
  editing?: boolean;
  sort?: boolean;
  hidden?: boolean;
  error?: boolean;
  onChange?: (value: V) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  labelRender?: (labels: (string | undefined)[] | undefined) => JSX.Element;
}

const arrayify = (value?: ValueType) => {
  let values: string[] | undefined;
  if (value) {
    values = Array.isArray(value) ? value : [value];
  }
  return values;
};

const filterCustomOptions = (customOptions: string[], values?: string[]) => {
  return customOptions.filter((customOption) => values?.includes(customOption));
};

const Selection = React.forwardRef(
  <V extends ValueType>(props: SelectionProps<V>, ref: Ref<RefSelectProps>) => {
    let {
      value,
      options: rawOptions,
      customOption: allowCustomOption = false,
      editing = true,
      sort = false,
      hidden = false,
      error = false,
      onChange,
      labelRender,
      ...selectionProps
    } = props;

    let options = standardiseOptions(rawOptions);
    if (sort) {
      options.sort(compareOptions);
    }

    const values = arrayify(value);

    const [customOptions, setCustomOptions] = useState(
      (values ?? []).filter((value) => !options.some((option) => option.value === value))
    );
    if (allowCustomOption && customOptions) {
      options = [
        ...options,
        ...customOptions.map((customOption) => ({
          label: `[Custom] ${customOption}`,
          value: customOption,
        })),
      ];
    }

    if (hidden) {
      return null;
    }

    if (!editing) {
      const labels = getLabels(options, values, allowCustomOption);
      const render = labelRender ?? defaultLabelRender;
      return render(labels);
    }

    let onSelect;
    if (onChange) {
      onSelect = (newValue: V, _: DefaultOptionType | DefaultOptionType[]) => {
        setCustomOptions((customOptions) => filterCustomOptions(customOptions, arrayify(newValue)));
        onChange?.(newValue ?? "");
      };
    }

    return (
      <Select
        allowClear
        showSearch
        size="large"
        status={error ? "error" : ""}
        {...{ value, options }}
        {...selectionProps}
        ref={ref}
        onChange={onSelect}
        onSearch={(searchValue) => {
          if (searchValue && !containsOnlySpaces(searchValue)) {
            setCustomOptions((customOptions) =>
              Array.from(new Set([...filterCustomOptions(customOptions, values), searchValue]))
            );
          }
        }}
        optionFilterProp="label"
      />
    );
  }
);

export default Selection as <V extends ValueType>(
  props: SelectionProps<V> & React.RefAttributes<RefSelectProps>
) => React.ReactElement<any, any> | null;
