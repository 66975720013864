
import useSWR, { mutate } from "swr";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { fetcher } from "../../../util/request";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-alpine.css";
import { theme, Row, Col, Form, Select, Input, Space, Button, Typography, Tag } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { TweenOneGroup } from 'rc-tween-one'
import { Card } from 'semantic-ui-react';
import { useAsyncFn } from "react-use";
import axios from 'axios';
import Loading from "../../../components/wrappers/Loading";

const GroupLabels = ({ value, data, allGroups }) => {
  const [groups, setGroups] = useState(value);

  const [{ }, changeGroups] = useAsyncFn(async (groups) => {
    await axios.patch(`/admin/contributor/${data.id}/patch/`, { groups });
    setGroups(groups);
  })

  return <Select
    onChange={changeGroups}
    mode="multiple"
    placeholder={"Add permission groups"}
    options={allGroups?.map(group => {
      return {
        value: group,
        label: group,
      }
    })}
    value={groups}
    style={{ width: '100%' }}
  />
}

const SubmitButton = ({ form, refresh }) => {
  const [submittable, setSubmittable] = useState(false);

  const values = Form.useWatch([], form);

  const [{ }, createNewContributor] = useAsyncFn(async () => {
    await axios.post('/admin/contributor/create', {
      email: `${values.email}@accessabroadhk.org`,
      name: values.name
    })
    refresh();
  }, [values])

  useEffect(() => {
    form.validateFields({ "create-new-contributor": true }).then(() => {
      setSubmittable(true);
    }, () => {
      setSubmittable(false);
    })
  }, [values]);

  return (
    <Button form={form} type="primary" disabled={!submittable} onClick={createNewContributor}>
      Create
    </Button>
  )
}


const UserCreationInput = ({ refresh }) => {
  const [form] = Form.useForm();
  return (
    <Card className="w-100" style={{ maxWidth: '500px' }}>
      <Card.Content header='Add new contributor' />
      <Card.Content>
        <Form form={form} name="create-new-contributor" layout="horizontal">
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input addonAfter={<Typography style={{ width: '150px' }}> @accessabroadhk.org </Typography>} />
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Space>
            <SubmitButton form={form} refresh={refresh} />
          </Space>
        </Form>
      </Card.Content>
    </Card>
  )
}

const PermissionGroupList = ({ groups, refresh }) => {
  const { token } = theme.useToken();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = async (removedGroup: string) => {
    await axios.delete(`/admin/permission-group/${removedGroup}`).then(response => refresh())
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = async () => {
    if (inputValue && groups.indexOf(inputValue) === -1) {
      await axios.post(`/admin/permission-group/${inputValue}/create`).then(_ => refresh())
    }
    setInputVisible(false);
    setInputValue('');
  };

  const forMap = (tag: string) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = groups.map(forMap);

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  return (
    <>

      <Card className="w-100" style={{ maxWidth: '500px' }}>
        <Card.Content header='Manage permission groups' />
        <Card.Content>
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: 'from',
              duration: 100,
            }}
            onEnd={(e) => {
              if (e.type === 'appear' || e.type === 'enter') {
                (e.target as any).style = 'display: inline-block';
              }
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {tagChild}
            {inputVisible ? (
              <Input
                ref={inputRef}
                type="text"
                size="middle"
                style={{ width: 78 }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            ) : (
              <Tag onClick={showInput} style={tagPlusStyle} >
                <PlusOutlined />New Group
              </Tag>
            )}
          </TweenOneGroup>
        </Card.Content>
      </Card >
    </>
  );
}

const UserList = () => {
  const {
    data: contributors,
    mutate: refreshContributors,
    isLoading: loadingContributors,
  } = useSWR(
    `/admin/contributor/list`,
    fetcher
  );

  const {
    data: permissionGroups,
    mutate: refreshPermissionGroups,
    isLoading: loadingGroups
  } = useSWR(
    `/admin/permission-group/list`,
    fetcher
  );

  const columnDefs = useMemo(() => [
    {

      field: "email",
      cellRenderer: ({ value }) => {
        return value?.replace("@accessabroadhk.org", "")
      }
    },
    {
      field: "name",
      editable: true,
      valueSetter: (params) => {
        axios.patch(`/admin/contributor/${params.data.id}/patch/`, { name: params.newValue }).then(() => {
          params.data.name = params.newValue;
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: [params.column],
          })
        });
        return false;
      }
    },
    {
      field: "groups",
      editable: true,
      cellRenderer: GroupLabels,
      cellRendererParams: {
        allGroups: permissionGroups
      }
    },
    {
      field: "disabled",
      editable: true,
      filter: true,
      valueSetter: (params) => {
        axios.patch(`/admin/contributor/${params.data.id}/patch/`, { disabled: params.newValue }).then(() => {
          params.data.name = params.newValue;
          params.api.refreshCells({
            rowNodes: [params.node],
            columns: [params.column],
          })
        });
        return false;
      }
    }
  ], [permissionGroups])

  return (

    <Loading loading={loadingContributors || loadingGroups}>
      <div className="mx-3 mt-3">
        <Row className="my-3">
          <Col span={12}>
            <UserCreationInput refresh={refreshContributors} /> </Col>
          <Col span={12}>

            <PermissionGroupList groups={permissionGroups} refresh={refreshPermissionGroups} />
          </Col>
        </Row>
        <div className="w-100 ag-theme-alpine" style={{ height: "100vh" }}>
          <AgGridReact
            enableRangeSelection={true}
            columnDefs={columnDefs}
            defaultColDef={{
              resizable: true,
              filter: true,
              width: 300,
              sortable: true,
            }}
            rowData={contributors}
          />
        </div>
      </div>
    </Loading>)
}

export default UserList;