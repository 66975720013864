import { Result } from "antd";
import React from "react";
import { Icon, Message } from "semantic-ui-react";
import ErrorBoundary from "../../../../components/wrappers/ErrorBoundary";
import useReloadPrompt from "../../../../hooks/useReloadPrompt";
import MaxWidthContainer from "../../../layout/MaxWidthContainer";

interface FormPreviewerProps<DraftValues> {
  values?: DraftValues;
  editing: boolean;
  component: React.FC<{ values?: DraftValues; editing: boolean }>;
}

const ErrorPronePreviewer = <DraftValues,>({
  values,
  editing,
  component,
}: FormPreviewerProps<DraftValues>) => {
  useReloadPrompt(editing);
  return (
    <>
      {editing && (
        <Message warning icon>
          <Icon name="warning sign" />
          <Message.Content>
            <Message.Header>Do not refresh this page.</Message.Header>
            <p>You will lose all your edits.</p>
          </Message.Content>
        </Message>
      )}
      <div className="px-4 py-2">
        <MaxWidthContainer centered>
          {React.createElement(component, { values, editing })}
        </MaxWidthContainer>
      </div>
    </>
  );
};

const Previewer = <DraftValues,>(props: FormPreviewerProps<DraftValues>) => (
  <ErrorBoundary
    fallback={
      <Result
        status="error"
        title="Compilation Failed"
        subTitle="Some of your inputs are invalid. Please go back to the previous page and modify them."
      />
    }
  >
    <ErrorPronePreviewer {...props} />
  </ErrorBoundary>
);

export default Previewer;
