import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Divider, Image, Label, SemanticCOLORS } from "semantic-ui-react";
import StatusLabel from "../../../../components/aahk/StatusLabel";
import { Selection } from "../../../../components/inputs";
import { LabelledOption } from "../../../../components/inputs/util";
import { ApplicationStatus, UNI_ABBREVIATIONS, UNI_LOGOS } from "../../../../constants";
import Application from "../../../../types/admin/Application";
import "./Summary.css";

interface SummaryProps {
  application: Application;
}

const Summary: React.FC<SummaryProps> = ({ application }) => {
  const info = {
    id: application.id,
    surname: application.savedForm.surname,
    firstName: application.savedForm.firstName,
    preferredName: application.savedForm.preferredName,
    email: application.email as string,
    phone: application.savedForm.phone as string,
    gender: application.savedForm.gender as string,
    // group: application.group,
    status: application.processingData.status as ApplicationStatus,
    school: application.savedForm.school as string,
    qualifications: application.savedForm.qualifications as string[],
    firstUniChoice: application.savedForm.firstUniChoice as string,
    firstSubjectChoice: application.savedForm.firstSubjectChoice as string,
    secondUniChoice: application.savedForm.secondUniChoice as string,
    secondSubjectChoice: application.savedForm.secondSubjectChoice as string,
    academicMentors: application.processingData.academicMentors as string[],
    scholarshipMentors: application.processingData.scholarshipMentors as string[],
    personInCharge: application.processingData.personalInCharge as string,
  };

  return (
    <div>
      {/* <Header info={info} /> */}
      <SummaryRows
        {...{
          info,
          ...application.processFormConfig.context,
        }}
      />
      <UniSubjectTab
        uni={info.firstUniChoice}
        subject={info.firstSubjectChoice}
        label="Choice 1"
        color="purple"
      />
      <UniSubjectTab
        uni={info.secondUniChoice}
        subject={info.secondSubjectChoice}
        label="Choice 2"
        color="orange"
      />
    </div>
  );
};

const RowLayout: React.FC<{ children: [React.ReactNode, React.ReactNode] }> = ({
  children: [label, children],
}) => {
  return (
    <Row className="my-3 my-sm-2 align-items-center">
      <Col xs={0} sm={4} className="p-0">
        {label}
      </Col>
      <Col className="p-0">{children}</Col>
    </Row>
  );
};

interface SummaryRowsProps {
  info: {
    email: string;
    phone: string;
    school: string;
    qualifications: string[];
    status: ApplicationStatus;
    personInCharge: string;
    academicMentors: string[];
    scholarshipMentors: string[];
  };
  academicMentors: LabelledOption[];
  scholarshipMentors: LabelledOption[];
  committeeMembers: LabelledOption[];
}

const SummaryRows: React.FC<SummaryRowsProps> = ({
  info,
  academicMentors,
  scholarshipMentors,
  committeeMembers,
}) => (
  <Container fluid className="mb-3">
    <RowLayout>
      <h5>Contact</h5>
      <span>
        <a href={`mailto:${info.email}`}>{info.email}</a> | {info.phone}
      </span>
    </RowLayout>
    <RowLayout>
      <h5>School</h5>
      <span>{info.school}</span>
    </RowLayout>
    <RowLayout>
      <h5>Qualifications</h5>
      <div>
        {info.qualifications.map((qualification: string) => (
          <Label horizontal>{qualification.replace(" (or equivalent)", "")}</Label>
        ))}
      </div>
    </RowLayout>
    {/* <RowLayout>
      <span>Group</span>
      <Selection value={info.group} options={GROUPS} sort className="w-75" />
    </RowLayout> */}
    <RowLayout>
      <h5>Status</h5>
      <StatusLabel status={info.status ?? ApplicationStatus.RECEIVED} />
    </RowLayout>
    <Divider />
    <RowLayout>
      <h5>Person-in-charge</h5>
      <Selection
        value={info.personInCharge}
        options={committeeMembers}
        editing={false}
        mode="multiple"
      />
    </RowLayout>
    <RowLayout>
      <h5>Academic mentors</h5>
      <Selection
        value={info.academicMentors}
        options={academicMentors}
        editing={false}
        mode="multiple"
      />
    </RowLayout>
    <RowLayout>
      <h5>Scholarship mentors</h5>
      <Selection
        value={info.scholarshipMentors}
        options={scholarshipMentors}
        editing={false}
        mode="multiple"
      />
    </RowLayout>
  </Container>
);

interface UniSubjectTabProps {
  uni: string;
  subject: string;
  label: string;
  color: SemanticCOLORS;
}

const UniSubjectTab: React.FC<UniSubjectTabProps> = ({ uni, subject, label, color }) => {
  return (
    <div className="uni-choice">
      <Container fluid>
        <Row>
          <Col xs={2} className="p-0 d-flex">
            <Image
              src={UNI_LOGOS[uni]}
              className="uni-logo-application m-auto"
              alt={UNI_ABBREVIATIONS[uni]}
            />
          </Col>
          <Col className="ps-4 py-1">
            <Label color={color} ribbon="right">
              {label}
            </Label>
            <Row className="mb-1" style={{ marginRight: "5rem" }}>
              <h5 className="px-0">{uni}</h5>
            </Row>
            <Row>{subject}</Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Summary;
