import { useForm } from "react-hook-form";
import { FormBodyConfig } from "../../../../types/FormBodyConfig";
import { FormState } from "../util";
import CallbacksInvoker from "../CallbacksInvoker";
import { createFormState } from "../util";

const useFormState = (formConfig?: FormBodyConfig, formValues?: Record<string, unknown>) => {
  let formState = {};
  if (formConfig && formValues) {
    const rawFormState = createFormState(formConfig.questionConfig, formValues);
    const callbacksInvoker = new CallbacksInvoker(rawFormState, formConfig.callbacks);
    formState = callbacksInvoker.invoke();
  }
  return useForm<FormState>({ values: formState, defaultValues: formState, mode: "all" });
};

export default useFormState;
