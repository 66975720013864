import dayjs from "dayjs";
import { InputtedFormConfig, SerializedFormConfig } from "./appFormConfigs/types";
import _ from "lodash";
import AppFormPreviewer from "./appFormConfigs/AppFormPreviewer";
import {
  ConfigVariables,
  ExtractStringKeys,
  Stringify,
} from "../../components/aahk/configManager/types";
import ConfigManager from "../../components/aahk/ConfigManager";

const CONFIG_VARIABLES: ConfigVariables<ExtractStringKeys<SerializedFormConfig>> = {
  id: {
    type: "metadata",
    label: "ID",
  },
  timestamp: {
    type: "metadata",
    label: "Timestamp",
  },
  cycle: {
    type: "year",
    label: "Cycle",
  },
  deadline: {
    type: "date",
    label: "Deadline",
    editable: true,
  },
  serializedQuestionConfigGenerator: {
    type: "js",
    label: "Questions",
  },
  serializedCallbackGenerator: {
    type: "js",
    label: "Callbacks",
  },
  sectionConfig: {
    type: "js",
    label: "Sections",
    editable: true,
  },
  courseList: {
    type: "js",
    label: "Course List",
    editable: true,
  },
  uniList: {
    type: "js",
    label: "University List",
    editable: true,
  },
  examList: {
    type: "js",
    label: "Exam List",
    editable: true,
  },
  schoolList: {
    type: "js",
    label: "School List",
    editable: true,
  },
};

const prepareConfigForInput = ({
  cycle,
  deadline,
  ...config
}: Stringify<SerializedFormConfig>): InputtedFormConfig => ({
  cycle: dayjs(cycle),
  deadline: dayjs(deadline),
  ...config,
});

const prepareConfigForUpload = ({
  id,
  cycle,
  deadline,
  timestamp,
  serializedQuestionConfigGenerator,
  serializedCallbackGenerator,
  ...inputtedFormConfig
}: InputtedFormConfig): SerializedFormConfig => {
  // eslint-disable-next-line no-eval
  const parsedConfig = _.mapValues(inputtedFormConfig, (value) => eval(`(${value})`));
  return {
    id,
    cycle: cycle.year(),
    deadline: deadline.toISOString(),
    timestamp,
    serializedQuestionConfigGenerator,
    serializedCallbackGenerator,
    ...parsedConfig,
  };
};

const AppFormConfigs = () => (
  <ConfigManager
    endpoint="app-form-config"
    configVariables={CONFIG_VARIABLES}
    {...{ prepareConfigForInput, prepareConfigForUpload }}
    previewerComponent={AppFormPreviewer}
  />
);

export default AppFormConfigs;
