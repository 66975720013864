import { Route, Routes } from "react-router-dom";
import Admin from "./views/admin";
import Apply from "./views/apply";
import Error404 from "./views/errors/Error404";
// import Error500 from "./views/errors/Error500";

const App = () => (
  <Routes>
    <Route path="/admin/*" Component={Admin} />
    <Route path="/apply/*" Component={Apply} />
    {/* <Route path="/error-500" Component={Error500} /> */}
    <Route path="/*" element={<Error404 mode="apply" buttonText="Back to Login" />} />
  </Routes>
);

export default App;
