import { FormControlLabel, Switch } from "@mui/material";
import { Modal, Tabs, message } from "antd";
import axios from "axios";
import _ from "lodash";
import { useContext, useState } from "react";
import { useAsyncFn } from "react-use";
import { Button } from "semantic-ui-react";
import AppFormBody, { useFormState } from "../../components/aahk/AppFormBody";
import ErrorBoundary from "../../components/wrappers/ErrorBoundary";
import Loading from "../../components/wrappers/Loading";
import useApplication from "../../hooks/admin/useApplication";
import Actions from "./application/components/Actions";
import Summary from "./application/components/Summary";
import { Header } from "./application/components/Header";
import { HeaderProps } from "./application/components/Header";
import MaxWidthContainer from "../../components/layout/MaxWidthContainer";
import { Selection, Text } from "../../components/inputs";
import { AuthContext } from "./withAuth";
import Permissions from "./application/components/Permissions";
import { useForm } from "react-hook-form";

const Application = () => {
  const { groups } = useContext(AuthContext);

  const [editing, setEditing] = useState(false);

  const { applicationId, application, error, isLoading, mutate } = useApplication();
  const [{ loading: updating }, update] = useAsyncFn(mutate);

  const appFormMethods = useFormState(application?.appFormConfig, application?.savedForm);
  const processFormMethods = useFormState(
    application?.processFormConfig,
    application?.processingData
  );
  const permissionForm = useForm({
    values: {
      users: application?.users,
      groups: application?.groups
    }
  })

  const [{ loading: submitting }, onEdit] = useAsyncFn(async () => {
    const savedForm = _.mapValues(appFormMethods.getValues(), ({ value }) => value);
    const processingData = _.mapValues(processFormMethods.getValues(), ({ value }) => value);
    const { users, groups } = permissionForm.getValues()

    await axios
      .patch(`/admin/application/edit/${applicationId}`, { savedForm, processingData, users, groups })
      .then((response) => setEditing(false))
      .catch((error) => message.error("Cannot save edits. Please try again."));

    if (application) {
      mutate({
        ...application,
        savedForm,
        processingData,
        users: users ?? [],
        groups: groups ?? [],
      });
    }
  }, [application]);

  const [unsafeActions, setUnsafeActions] = useState<boolean>(false);

  return (
    <Loading loading={isLoading || updating} error={error}>
      <div className="d-flex flex-wrap align-items-center">
        <div className="flex-grow-1 mt-0">
          <Header
            {...({
              surname: application?.savedForm.surname,
              firstName: application?.savedForm.firstName,
              preferredName: application?.savedForm.preferredName,
              gender: application?.savedForm.gender,
            } as HeaderProps)}
          />
        </div>
        <FormControlLabel
          control={<Switch checked={unsafeActions} />}
          label="Unsafe actions"
          onChange={(event, checked) => {
            if (checked) {
              Modal.confirm({
                title: "危險動作: 後果自負!",
                content: (
                  <div>
                    <p>
                      These actions can permanently corrupt application data if used improperly.
                    </p>
                    <h5>
                      Do not proceed unless you are confident in your understanding of these
                      actions.
                    </h5>
                  </div>
                ),
                okText: "Proceed",
                okType: "primary",
                okButtonProps: { danger: true },
                onOk: (close) => {
                  setUnsafeActions(checked);
                  close();
                },
                width: 600,
              });
            } else {
              setUnsafeActions(checked);
            }
          }}
          className="me-4 mt-3 mt-md-0"
        />
        {editing ? (
          <div className="mt-3 mt-md-0">
            <Button
              positive
              size="small"
              icon="check"
              content="Confirm"
              loading={submitting}
              onClick={async () => {
                await onEdit();
                setEditing(false);
              }}
              className="mx-2"
            />
            <Button
              size="small"
              icon="x"
              content="Cancel"
              onClick={() => {
                appFormMethods.reset(undefined, { keepDefaultValues: true });
                processFormMethods.reset(undefined, { keepDefaultValues: true });
                setEditing(false);
              }}
            />
          </div>
        ) : (
          <div className="mt-3 mt-md-0">
            <Button
              primary
              size="small"
              icon="pencil"
              content="Edit"
              onClick={() => {
                setEditing(true);
              }}
            />
          </div>
        )}
      </div>
      {application && (
        <Tabs
          defaultActiveKey="overview"
          items={[
            {
              key: "overview",
              label: "Overview",
              children: (
                <ErrorBoundary>
                  <MaxWidthContainer width={600}>
                    <Summary {...{ application }} />
                  </MaxWidthContainer>
                </ErrorBoundary>
              ),
            },
            {
              key: "form-data",
              label: "Form",
              children: (
                <ErrorBoundary>
                  <AppFormBody
                    editing={editing}
                    formMethods={appFormMethods}
                    allErrorsVisible={editing}
                    sectionConfig={application.appFormConfig.sectionConfig}
                    callbacks={application.appFormConfig.callbacks}
                    fileUploadLink={`/admin/application/add-file/${applicationId}/app-form`}
                    fileDeleteLink={`/admin/application/remove-file/${applicationId}/app-form`}
                  />
                </ErrorBoundary>
              ),
            },
            {
              key: "processing-data",
              label: "Processing",
              children: (
                <ErrorBoundary>
                  <AppFormBody
                    editing={editing}
                    formMethods={processFormMethods}
                    allErrorsVisible={editing}
                    sectionConfig={application.processFormConfig.sectionConfig}
                    callbacks={application.processFormConfig.callbacks}
                    fileUploadLink={`/admin/application/add-file/${applicationId}/process-form`}
                    fileDeleteLink={`/admin/application/remove-file/${applicationId}/process-form`}
                  />
                </ErrorBoundary>
              ),
            },
            {
              key: "actions",
              label: "Actions",
              children: <Actions {...{ applicationId, application, update, unsafeActions }} />,
            },

            ...groups?.includes("admin") ?
              [{
                key: "permissions",
                label: "Permissions",
                children: <Permissions editing={editing} formMethods={permissionForm} />
              }] : []
          ]}
        ></Tabs>
      )}
    </Loading>
  );
};

export default Application;
