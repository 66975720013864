import { Route, Routes } from "react-router-dom";
import Page from "../../components/layout/Page";
import ErrorBoundary from "../../components/wrappers/ErrorBoundary";
import Error404 from "../errors/Error404";
import AppFormConfigs from "./AppFormConfigs";
import Applications from "./Applications";
import EmailTemplates from "./EmailTemplates";
import NavigationBar from "./NavigationBar";
import ProcessFormConfigs from "./ProcessFormConfigs";
import UserList from "./UserManagement/UserList";
import Application from "./Application";
import withAuth from "./withAuth";

export const ROUTES = [
  { path: "contributors/", element: <UserList /> },
  { path: "applications?", element: <Applications /> },
  { path: "application/:id", element: <Application /> },
  { path: "app-form-configs/*", element: <AppFormConfigs /> },
  { path: "process-form-configs/*", element: <ProcessFormConfigs /> },
  { path: "email-templates/*", element: <EmailTemplates /> },
  { path: "*", element: <Error404 mode="admin" buttonText="Back to Applications" /> },
];

const Admin: React.FC = () => {
  return (
    <Page.Wrapper>
      <NavigationBar />
      <Page.Body>
        <ErrorBoundary>
          <Routes>
            {ROUTES.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Routes>
        </ErrorBoundary>
      </Page.Body>
    </Page.Wrapper>
  );
};

export default withAuth(Admin);
