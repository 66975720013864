import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { IconButton, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NestedMenuItem } from "mui-nested-menu";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon, Image } from "semantic-ui-react";
import LogoImage from "../../assets/aahk-logo.jpg";
import "./NavigationBar.css";

interface Page {
  path: string;
  name: string;
}

interface SubMenu {
  name: string;
  submenu: Page[];
}

interface NavigationBarProps {
  menu: (Page | SubMenu)[];
  logout?: () => void;
}

const CollapsibleMenu: React.FC<{ menu: NavigationBarProps["menu"] }> = ({ menu }) => {
  const [anchorElNav, setAnchorElNav] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorElNav);
  const openNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
  const closeNavMenu = () => setAnchorElNav(null);

  const navigate = useNavigate();

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        className="p-3 d-xs-block d-xl-none"
        onClick={openNavMenu}
      >
        <Icon name="bars" />
      </IconButton>

      <Menu
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        onClose={closeNavMenu}
        sx={{
          display: { xs: "block", xl: "none" },
        }}
      >
        {menu.map((item) => {
          if ("submenu" in item) {
            const { name, submenu } = item;
            return (
              <NestedMenuItem
                key={name}
                leftIcon={<div className="ms-1" />}
                label={name}
                parentMenuOpen={open}
              >
                {submenu.map(({ path, name }) => (
                  <MenuItem
                    key={path}
                    onClick={() => {
                      navigate(path);
                      closeNavMenu();
                    }}
                  >
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </NestedMenuItem>
            );
          } else {
            const { path, name } = item;
            return (
              <MenuItem
                key={path}
                onClick={() => {
                  navigate(path);
                  closeNavMenu();
                }}
              >
                <Typography>{name}</Typography>
              </MenuItem>
            );
          }
        })}
      </Menu>
    </>
  );
};

const Logo: React.FC = () => (
  <>
    <div className="py-1 me-2">
      <Image src={LogoImage} circular className="logo" />
    </div>
    <Typography variant="h5" className="d-none d-sm-block d-md-none">
      Access Abroad HK
    </Typography>
    <Typography variant="h5" className="mt-1 mb-2 me-5 d-none d-md-block">
      Access Abroad Hong Kong
    </Typography>
  </>
);

const Timer: React.FC = () => {
  const [time, setTime] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()));
    return () => clearInterval(interval);
  }, []);

  const currentTime = new Date(time).toLocaleTimeString([], { hourCycle: "h23" });
  return <Typography variant="body1">{currentTime}</Typography>;
};

const Submenu: React.FC<{ name: string; pages: Page[] }> = ({ name, pages }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const navigate = useNavigate();

  return (
    <div>
      <Button key={name} color="inherit" className="pascal-case me-2" onClick={handleClick}>
        {name}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {pages.map(({ path, name }) => (
          <MenuItem
            onClick={() => {
              navigate(path);
              handleClose();
            }}
          >
            <Typography>{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const NavigationBar: React.FC<NavigationBarProps> = ({ menu, logout }) => {
  const navigate = useNavigate();

  return (
    <AppBar position="static" className="appbar d-flex w-100">
      <Toolbar className="toolbar pl-3">
        <CollapsibleMenu menu={menu} />
        <Logo />

        <div className="d-none d-xl-flex">
          {menu.map((item) => {
            if ("submenu" in item) {
              const { name, submenu } = item;
              return <Submenu name={name} pages={submenu} />;
            } else {
              const { path, name } = item;
              return (
                <Button
                  key={path}
                  color="inherit"
                  className="pascal-case me-2"
                  onClick={() => navigate(path)}
                >
                  {name}
                </Button>
              );
            }
          })}
        </div>

        <div className="flex-grow-1" />
        <Button
          children="Logout"
          color="inherit"
          endIcon={<ExitToAppIcon />}
          className="pascal-case mx-4"
          onClick={logout}
        />
        <Timer />
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
