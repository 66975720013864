import { Icon, Label } from "semantic-ui-react";

const GenderIcon: React.FC<{ gender: string }> = ({ gender }) => {
  let genderIcon = null;
  switch (gender) {
    case "Male":
      genderIcon = <Icon as="i" className="fas fa-male" color="blue" size="large" />;
      break;
    case "Female":
      genderIcon = <Icon as="i" className="fas fa-female" color="pink" size="large" />;
      break;
    case "Prefer not to say":
      genderIcon = <Icon name="question" size="large" />;
      break;
    default:
      genderIcon = <Label color="violet" content={gender} horizontal className="mr-2" />;
  }
  return genderIcon;
};

export default GenderIcon;
